// noinspection DuplicatedCode

import React, { useState } from 'react'
import {
  Button, Checkbox,
  Divider,
  Form, Icon,
  Label,
  Message, Popup,
  Segment, Select,
} from 'semantic-ui-react'
import './add-user.css'
import { Avatar, Person } from '../../model/family-models'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

const ModifyUser = (props) => {
  const {registration, referenceData, submit, checkUserNameExists, checkEmailExists, toggleModal, person} = props
  const [genderError, setGenderError] = useState(undefined)
  const [formValidationError, setFormValidationError] = useState(undefined)
  const [gender, setGender] = useState(person?.gender)
  const [dateOfBirth, setDateOfBirth] = useState( person === undefined ? dayjs() : dayjs(person.dateOfBirth))
  const [selectedFile, setSelectedFile] = useState(null)


  const handleSubmit = (event) => {
    if(genderError !== undefined){
      setFormValidationError("Form entries are not valid please check below")
      return
    }else {
      setFormValidationError(undefined)
    }

    const formData = new FormData(event.target)
    const roles = formData.getAll("role")

    if(!validateRoleCombination(roles)){
      setFormValidationError("Users can be either a Child or Family Admin / Parent")
    }
    const modifiedUser = new Person(
      formData.get("firstName"),
      formData.get("lastName"),
      dateOfBirth.format("DD-MM-YYYY"),
      gender,
      roles,
      formData.get("username"),
      person?.id
    )

    if( !selectedFile) {
      submit(modifiedUser)
      toggleModal(false)
      event.preventDefault()
    }else {
      const reader = new FileReader()
      reader.onload = function () {
        const avatarData = reader.result

        const avatar = new Avatar(selectedFile.type, avatarData)
        submit(modifiedUser, avatar)
        toggleModal(false)
        event.preventDefault()
      }
      // noinspection JSCheckFunctionSignatures
      reader.readAsArrayBuffer(selectedFile)
    }


  }

  const validateRoleCombination = (roles) => {
    const isChild = roles.includes("Child")
    return !(isChild && roles.length !== 1);

  }

  const checkUsername = (e) => {
    checkUserNameExists({'username': e.target.value } )
  }
  const errorMessageUsername = () => {
    return (registration !== undefined && registration.usernameCheck !== undefined && registration.usernameCheck.exists ?
      <Label prompt color="red" size={'small'}>Username already exists. Please pick a different name</Label> :
      <div/>)
  }


  const genderOptions = () => {
    const genders = referenceData.genders.map( (item, index) => { return {'key': index.id, 'text': item.name, 'value': item.name } } )
    return [{'key': -1, 'text': '', 'value':''}, ...genders]
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  }

  let message = <div/>
 if (formValidationError !== undefined){
    // noinspection JSValidateTypes
   message = <Message negative>
      <Message.Header>Errors while validating the form</Message.Header>
      <Message.Content>{formValidationError}</Message.Content>
    </Message>
  }

  return (
    <div>
      {message}
      <Segment>
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>First Name</label>
              <input placeholder="First Name" name="firstName" defaultValue={person?.firstName} />
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <input placeholder="Last Name" name="lastName" defaultValue={person?.lastName} />
            </Form.Field>
            <Form.Field>
              <label>Username</label>
              <input placeholder="Username" name="username" onBlur={checkUsername} defaultValue={person?.username} autoComplete={"username"}/>
              { errorMessageUsername() }
            </Form.Field>
          </Form.Group>

          <Divider hidden/>

          <Form.Group widths={'equal'}>
            <Form.Field>
              <label>Date of Birth</label>
              <DatePicker onChange={value => setDateOfBirth(value)} value={dateOfBirth}/>
            </Form.Field>
            <Form.Field>
              <label>Gender</label>
              <Select options={genderOptions()} value={gender} onChange={(event, { value }) => setGender(value)} />
            </Form.Field>
            <Form.Field>
              <label>Avatar</label>
              <input type="file" onChange={handleFileChange} />
            </Form.Field>
          </Form.Group>

          <Divider hidden/>

          <Form.Group>
            <Form.Field>
              <table>
                <tbody>
                <tr>
                  <td><Checkbox style={{padding: "1em"}} name="role" label="Family Admin" value="FamilyAdmin" defaultChecked={person?.roles.includes("FamilyAdmin")}/></td>
                  <td><Popup content={"Family Admins can add / delete members, see all balances and book transactions for all chilren"} trigger={<Icon name={'info circle'}/>}/></td>
                </tr>
                <tr>
                  <td><Checkbox style={{padding: "1em"}} name="role" label="Parent" value="Parent" defaultChecked={person?.roles.includes("Parent")}/></td>
                  <td><Popup content={"Parents can book transactions for all children as well as see all their accounts"} trigger={<Icon name={'info circle'}/>}/></td>
                </tr>
                <tr>
                  <td><Checkbox style={{padding: "1em"}} name="role" label="Child" value="Child" defaultChecked={person?.roles.includes("Child")}/></td>
                  <td><Popup content={"Children become an account and can see their own balance and transactions"} trigger={<Icon name={'info circle'}/>}/></td>
                </tr>
                </tbody>
              </table>
            </Form.Field>
          </Form.Group>
          <Divider hidden/>

          <Form.Group widths={'equal'}>
            <Form.Field>
              <label>Username</label>
              <input placeholder="Username" name="username" onBlur={checkUsername} defaultValue={person?.username} autoComplete={"username"}/>
              { errorMessageUsername() }
            </Form.Field>
          </Form.Group>
          <Divider hidden/>
          <Button type="submit">Submit</Button>
        </Form>
      </Segment>
    </div>
  )
}
export default ModifyUser