import { FAMILY_ADDED_FULFILLED, FAMILY_LOADED_FULFILLED } from '../../actions/family-actions'
import { LOGOUT_USER } from '../../actions/authentication-actions'

const initialState = { loadingComplete: false }

export default function familyReducer (state = initialState, action) {

  switch (action.type) {
    case FAMILY_ADDED_FULFILLED:
      return {
        loadingComplete: true,
        payload: action.payload.payload,
        errors: action.payload.errors
      }
    case FAMILY_LOADED_FULFILLED:
      return {
        loadingComplete: true,
        payload: action.payload.payload,
        errors: action.payload.errors
      }
    case LOGOUT_USER:
      return {}
    default:
      return state
  }
}