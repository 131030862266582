import * as RefDataApi from '../api/reference-data-api'

export const LOAD_REFERENCE_DATA = 'reference-data/load-generic'
export const LOAD_TRANSACTION_PURPOSE = 'reference-data/load-transaction-purpose'
export const ADD_TRANSACTION_PURPOSE = 'reference-data/add-transaction-purpose'
export const DELETE_TRANSACTION_PURPOSE = 'reference-data/delete-transaction-purpose'

export const LOAD_REFERENCE_DATA_FULFILLED = 'reference-data/load-generic_FULFILLED'
export const LOAD_TRANSACTION_PURPOSE_FULFILLED = 'reference-data/load-transaction-purpose_FULFILLED'
export const ADD_TRANSACTION_PURPOSE_FULFILLED = 'reference-data/add-transaction-purpose_FULFILLED'
export const DELETE_TRANSACTION_PURPOSE_FULFILLED = 'reference-data/delete-transaction-purpose_FULFILLED'

export const CLEAR_TRANSACTION_PURPOSE = 'reference-data/clear-transaction-purpose'

export function LoadReferenceData(){
  return {
    type: LOAD_REFERENCE_DATA,
    payload: RefDataApi.loadReferenceData()
  }
}

export function LoadTransactionPurpose(){
  return {
    type: LOAD_TRANSACTION_PURPOSE,
    payload: RefDataApi.loadTransactionPurpose()
  }
}

export function AddTransactionPurpose(name){
  return {
    type: ADD_TRANSACTION_PURPOSE,
    payload: RefDataApi.addTransactionPurpose(name)
  }
}

export function DeleteTransactionPurpose(id){
  return {
    type: DELETE_TRANSACTION_PURPOSE,
    payload: RefDataApi.deleteTransactionPurpose(id)
  }
}

export function ClearTransactionPurpose() {
  return {
    type: CLEAR_TRANSACTION_PURPOSE,
  }
}