import {
  USER_AUTHENTICATED_CHECK,
  USER_SIGNED_IN_FULFILLED,
  LOGOUT_USER
} from '../../actions/authentication-actions'


export default function authReducer (state = false, action) {

  switch (action.type) {
    case USER_AUTHENTICATED_CHECK:
      return  action.payload
    case USER_SIGNED_IN_FULFILLED:
      return action.payload.authStatus === "SUCCESS"
    case LOGOUT_USER:
      return  false
    default:
      return state
  }
}