import React from 'react'
import TransactionPurposeSettings
  from '../../components/settings/transaction-purpose-settings'
import { useDispatch, useSelector } from 'react-redux'
import {
  AddTransactionPurpose,
  DeleteTransactionPurpose,
} from '../../actions/reference-data-actions'

const TransactionPurposeSettingsContainer = () => {

  const transactionPurpose = useSelector( state => state.referenceData.transactionPurpose)
  const errors = useSelector( state => state.referenceData.errors)
  const userRoles = useSelector( state => state.user.roles)

  const dispatch = useDispatch()
  const handleDelete = (purpose) => {
    dispatch(DeleteTransactionPurpose([purpose])) // API expects a list of strings
  }

  const handleAdd = (purpose) => {
    dispatch(AddTransactionPurpose([purpose])) // API expects a list of strings
  }

  return <TransactionPurposeSettings userRoles={userRoles} handleAdd={handleAdd} handleDelete={handleDelete} transactionPurpose={transactionPurpose} errors={errors} />
}

export default TransactionPurposeSettingsContainer