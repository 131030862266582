import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DeletePostingSchedule,
  LoadPostingSchedule,
} from '../../actions/posting-actions'
import ScheduledPostingOverview
  from '../../components/dashboard/scheduled-posting-overview'

const ScheduledPostingOverviewContainer = (props) => {
  const { account } = props
  const scheduledPostings = useSelector( state => state.posting.scheduledPostings)

  const dispatch = useDispatch()
  const loadScheduledPostings = () => dispatch(LoadPostingSchedule(account.id))
  const deleteScheduledPosting = (id) => dispatch(DeletePostingSchedule(id))

  return <ScheduledPostingOverview account={account} scheduledPostings={scheduledPostings} loadScheduledPostings={loadScheduledPostings} deleteScheduledPosting={deleteScheduledPosting}/>
}

export default ScheduledPostingOverviewContainer