import React from 'react'
import { Container, Header } from 'semantic-ui-react'

const Home = () => {
  return (
    <Container textAlign={'justified'} fluid>
          <Header as='h1' >
            The Ultimate Pocket Money Tracker for Kids
          </Header>
          <Header as='h3' >
            Give your little ones the power to manage their finances and learn valuable money management skills in a fun and interactive way.
          </Header>
          <Header as='h4' >
            Our app features a simple and intuitive interface that is easy for kids to use. They can set savings goals, track their spending, and even earn rewards for good financial habits. Parents can also get involved and monitor their child's spending habits, set chores and allowances, and encourage positive financial behavior.
          </Header>
    </Container>
  )

}

export default Home