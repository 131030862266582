import React, { useEffect, useState } from 'react'
import {
  Button,
  Card, Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
  Menu,
  Message,
  Modal,
  ModalContent,
} from 'semantic-ui-react'
import AccountComponent from './account-component'
import AccountDetailsContainer from '../../containers/dashboard/account-details'
import AddPosting from '../../containers/dashboard/add-posting'
import './account-overview-parent.css'
import AddPostingSchedule from '../../containers/dashboard/add-posting-schedule'
import PostingScheduleOverviewContainer
  from '../../containers/dashboard/scheduled-posting-overview'

const AccountOverviewParent = (props) => {
  const { data, loadFamilyAccount, clearPostingState, referenceData, deletePosting } = props
  const amountCardsToBeDisplayed = data?.payload !== null && data?.payload !== undefined
    ? data.payload.length : 0

  const [openPosting, setOpenPosting] = useState(
    Array(amountCardsToBeDisplayed).fill(false))
  const [open, setOpen] = useState(Array(amountCardsToBeDisplayed).fill(false))
  const [addPostingSelection, setAddPostingSelection] = useState('ad-hoc')
  const [accountDetailsSelection, setAccountDetailsSelection] = useState('account-view')

  useEffect(loadFamilyAccount, [openPosting])


  if (data === undefined || data === null) {
    return (
      <Dimmer active>
        <Loader content="Loading Account Overview"/>
      </Dimmer>
    )
  }

  if (data.payload === undefined || data.payload === null) {
    return (

      <Container textAlign={'justified'} fluid>
        <Message negative>
          <Header as='h1' >
            It seems like something is missing !!!
          </Header>
        </Message>
        <Header as='h4' >
          We couldn't find a family for you or any accounts for children. Please head to the Family section and setup your Family and add your children as members.
          Once done you can come back and will see your children's accounts listed here.
        </Header>
      </Container>
    )
  }

  const toggleState = (indexToUpdate, value, setter) => {
    const newState = [...open]
    newState[indexToUpdate] = value
    setter(newState)
  }

  const accountsOverview = data.payload.sort(sortAccountOwner)
  .map(({ account, postings, range }, i) =>
      <div key={i} className="paddedAccountView">
        <div>
          <AccountComponent account={account}/>
          <div className="ui two buttons">
            <Button primary onClick={() => toggleState(i, true,
              setOpen)}>Details</Button>
            <Button secondary
                    onClick={() => toggleState(i, true, setOpenPosting)}>Add
              Transaction</Button>
          </div>
        </div>

        <Modal
          size={'fullscreen'}
          open={open[i]}
          onOpen={() => toggleState(i, true, setOpen)}
          onClose={() => {
            toggleState(i, false, setOpen)
            clearPostingState()
          }}>
          <Modal.Header>
            <Header block>
              <Icon name={'money bill alternate'}/>
              <Header.Content>
                Account Details
              </Header.Content>
            </Header>
          </Modal.Header>
          <Modal.Content>
            <Menu pointing secondary>
              <Menu.Item name="Account View" active={accountDetailsSelection === 'account-view'}
                         onClick={() => setAccountDetailsSelection('account-view')}/>
              <Menu.Item name="Active Schedule "
                         active={accountDetailsSelection === 'active-schedule'}
                         onClick={() => setAccountDetailsSelection('active-schedule')}/>
            </Menu>
            { createDetailsView(accountDetailsSelection, account,postings,range, deletePosting) }
          </Modal.Content>
        </Modal>

        <Modal size={'fullscreen'} open={openPosting[i]} onOpen={() => toggleState(i, true, setOpenPosting)} onClose={() => {
            toggleState(i, false, setOpenPosting)
            clearPostingState()
          }}>
          <Modal.Header>
            Create a transaction for {account.accountOwner.firstName}
          </Modal.Header>
          <ModalContent>
            <Menu pointing secondary>
              <Menu.Item name="Once Off Booking" active={addPostingSelection === 'ad-hoc'}
                         onClick={() => setAddPostingSelection('ad-hoc')}/>
              <Menu.Item name="Scheduled Booking"
                         active={addPostingSelection === 'scheduled'}
                         onClick={() => setAddPostingSelection('scheduled')}/>
            </Menu>
            {
              createAddPostingView(addPostingSelection, referenceData, account, () => {
                loadFamilyAccount()
                toggleState(i, false, setOpenPosting)
              })
            }
        </ModalContent>
      </Modal>
</div>,
)

  return (
    <Grid padded>
      <Grid.Row>
        <Header>Account Overview </Header>
      </Grid.Row>
      <Grid.Row>
        <p>Below you see an overview of all your family's accounts. In case any
          of your children are missing please go to your family management page
          to add.</p>
        <p>This will automatically setup an account for them as well.Currently
          only one account per child is supported</p>
      </Grid.Row>
      <Grid.Row>
        <Card.Group>
          {accountsOverview}
        </Card.Group>
      </Grid.Row>
    </Grid>

  )
}

const createAddPostingView = (addPostingSelection, referenceData, account, handleOpenPostingClose) => {
  return addPostingSelection === 'ad-hoc' ? <AddPosting
      referenceData={referenceData}
      account={account}
      handleOpenPostingClose={handleOpenPostingClose}/> :
    <AddPostingSchedule referenceData={referenceData}
                         account={account}
                         handleOpenPostingClose={handleOpenPostingClose}/>
}

const createDetailsView = (accountDetailsSelection, account, postings, range, deletePosting ) => {
  return accountDetailsSelection === 'account-view' ? <AccountDetailsContainer account={account} deletePosting={deletePosting}
                                                                               /> : <PostingScheduleOverviewContainer account={account}/>
}

const sortAccountOwner = (a, b) => {
  if (a.account.accountOwner.firstName > b.account.accountOwner.firstName) {
    return -1
  } else if (a.account.accountOwner.firstName <
    b.account.accountOwner.firstName) {
    return 1
  } else {
    return 0
  }
}
export default AccountOverviewParent