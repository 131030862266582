import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './state/store'
import {
  createHashRouter,
  RouterProvider,
} from 'react-router-dom'
import App from './App'
import './index.css'
import 'semantic-ui-css/semantic.min.css'
import reportWebVitals from './reportWebVitals'
import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'

const container = document.getElementById('root')
const root = createRoot(container)

const router = createHashRouter([
  {
    path: '*',
    element: <App/>,
  },
])

const firebaseConfig = {
  apiKey: "AIzaSyBGEMuyNRleV_Hsah1VtosTuykqTj2DKyk",
  authDomain: "my-pocket-money-d105e.firebaseapp.com",
  projectId: "my-pocket-money-d105e",
  storageBucket: "my-pocket-money-d105e.appspot.com",
  messagingSenderId: "339601524834",
  appId: "1:339601524834:web:676327afc627f35f30293b",
  measurementId: "G-WCR09F11G5",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const env = process.env.REACT_APP_ENV
console.log('Running Env:', env)
console.log('BaseUrl:', process.env.REACT_APP_BASE_URL)

if(env !== 'production'){
  const auth = getAuth();
  connectAuthEmulator(auth, 'http://localhost:9099')
}else{
  console.log("We are using the real authentication system in", env)
}


root.render(
  // TODO should use React.Strict but react-router causes an error message. Might change back later
    <React.Fragment>
      <Provider store={store}>
        <RouterProvider router={router}/>
      </Provider>
    </React.Fragment>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
