import React from 'react'
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Input, Message,
} from 'semantic-ui-react'

import './password-reset.css'

const PasswordReset = (props) => {
  const {handleSubmit, passwordReset} = props

  let message = <div/>
  if(passwordReset !== undefined && passwordReset.result){
    message = <Message positive>Please check your inbox to complete your password reset</Message>
  }else if(passwordReset !== undefined && !passwordReset.result){
    message = <Message negative>{passwordReset.error}</Message>
  }

  return (
    <div>
      {message}
      <Container style={{textAlign:'center'}}>
        Please enter your email below and submit to reset your password
      </Container>
    <Grid columns={3}>
      <Grid.Column><span/></Grid.Column>
      <Grid.Column>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <Input name='username' placeholder='Your email'/>
          </Form.Field>
          <Button type='submit' fluid color="facebook">Submit</Button>
        </Form>
      </Grid.Column>
      <Grid.Column><span/></Grid.Column>
    </Grid>
    </div>
    )
}

export default PasswordReset