import React, { useState } from 'react'
import { Button, Checkbox, Divider, Form, Image, Label, List, Message, Segment, Table } from 'semantic-ui-react'
import logo from '../../assets/logo-transparent.png'
import './user-registration.css'

import { CheckEmailExists, CheckUsernameExists, RegisterUser } from '../../actions/authentication-actions'
import { MemberRegistration } from '../../model/family-models'
import { Link } from 'react-router-dom'

const UserRegistration = (props) => {
  const { dispatch, registration, referenceData } = props

  const [passwordValidationErrors, setErrors] = useState([])
  const [passwordConfirmationValidationErrors, setErrorsConfirmation] = useState(undefined)
  const [formValidationError, setFormValidationError] = useState(undefined)
  const [isAdultConfirmed, setAdultConfirmed] = useState(false)

  const handleSubmit = (event) => {

    if (passwordValidationErrors.length !== 0 || passwordConfirmationValidationErrors !== undefined) {
      console.error('Form entries are not valid therefore can\'t submit')
      setFormValidationError('Form entries are not valid please check below')
      return
    } else if (!isAdultConfirmed) {
      setFormValidationError("Please confirm that you are over 18 years old")
      return
    } else {
      setFormValidationError(undefined)
    }

    const formData = new FormData(event.target)
    const newUser = new MemberRegistration(
      formData.get('firstName'),
      formData.get('lastName'),
      ['FamilyAdmin', 'Parent'],
      formData.get('username'),
      formData.get('passwordField'),
    )
    console.debug('Trying to register user:', newUser)
    dispatch(RegisterUser(newUser))
    event.preventDefault()
  }

  const checkUsername = (e) => {
    dispatch(CheckUsernameExists({ 'username': e.target.value }))
  }

  const checkPasswordRules = (e) => {
    const password = e.target.value
    const errors = []
    if (password.length < 8 || password.length > 20) {
      errors.push('The password has to be between 8 and 20 characters long. Yours is ' + password.length)
    }
    setErrors(errors)
  }

  const checkPasswordsIdentical = (e) => {
    const password = document.getElementsByName('passwordField')[0].value
    if (password !== e.target.value) {
      setErrorsConfirmation('Passwords don\'t match')
    } else {
      setErrorsConfirmation(undefined)
    }
  }

  const checkEmailAddress = (e) => {
    dispatch(CheckEmailExists({ 'email': e.target.value }))
  }

  const errorMessageUsername = () => {
    return (registration.usernameCheck !== undefined && registration.usernameCheck.exists ?
      <Label prompt color="red" size={'small'}>Username already exists. Please pick a different name</Label> :
      <div/>)
  }
  const errorMessageEmail = () => {
    return (registration.emailCheck !== undefined && registration.emailCheck.exists ?
      <Label prompt color="red" size={'small'}>Email already exists. Please reset your password if you forgot</Label> :
      <div/>)
  }

  let message = <div/>
  console.debug('registration', registration)
  if (registration.signup && registration.signup.success) {
    message = <Message positive>
      <Message.Header>Congratulations for successfully registering</Message.Header>
      <p><Link to="/login">Please click here to login an setup your family</Link></p>
    </Message>
  } else if (registration.signup && registration.signup.success !== undefined && registration.signup.success === false) {
    message = <Message negative>
      <Message.Header>{registration.signup.message}</Message.Header>
      <List divided relaxed>
        {registration.signup.underlyingErrors.map((error, index) => <List.Item key={index}>{error}</List.Item>)}
      </List>
    </Message>
  } else if (formValidationError !== undefined) {
    message = <Message negative>
      <Message.Header>{formValidationError}</Message.Header>
    </Message>
  }

  return (
    <div>
      <Table className={'ui very basic table'}>
        <Table.Body>
          <Table.Row>

            <Table.Cell>
              <h3>My Pocket Money Registration</h3>
              <Message>
                <Message.Header>Please read before signing up</Message.Header>
                <Message.Content>
                  The application needs to have a grown up to sign up first. Once a valid user they can create a family, add children and manage their children's pocket money in the application.
                  If you are a child who would like to use this app to manage your pocket money please ask your parents to sign up and set up a family.
                </Message.Content>
              </Message>
            </Table.Cell>
            <Table.Cell>
              <Image verticalAlign={'middle'} floated={'left'} size={'small'} src={logo}/>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Divider hidden/>
      {message}
      <Segment>
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>First Name</label>
              <input placeholder="First Name" name="firstName"/>
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <input placeholder="Last Name" name="lastName"/>
            </Form.Field>
          </Form.Group>
          <Divider hidden/>
          <Divider hidden/>
          <Form.Group widths={'equal'}>
            <Form.Field>
              <label>Username</label>
              <input placeholder="Password" name="username" onBlur={checkEmailAddress}/>
              {errorMessageEmail()}
            </Form.Field>
            <Form.Field>
              <label>Password</label>
              <input name={'passwordField'} type={'password'} onBlur={checkPasswordRules} placeholder="Password...."/>
              {passwordValidationErrors.length === 0 ? <div/> : <Label prompt color="red" size={'small'}>{passwordValidationErrors.join()} </Label>}
            </Form.Field>
            <Form.Field>
              <label>Password confirmation</label>
              <input name={'passwordConfirmation'} type={'password'} onBlur={checkPasswordsIdentical} placeholder="Password...."/>
              {passwordConfirmationValidationErrors === undefined ? <div/> : <Label prompt color="red" size={'small'}>{passwordConfirmationValidationErrors} </Label>}
            </Form.Field>
          </Form.Group>
          <Divider hidden/>
          <Form.Group>
            <Checkbox label="I confirm that I am over 18 years."
                      checked={isAdultConfirmed}
                      onChange={(_, data) => setAdultConfirmed(data.checked)}
            />
          </Form.Group>
          {/*<Form.Group>*/}
          {/*<Checkbox label="I agree to the Terms and Conditions"/>*/}
          {/*</Form.Group>*/}
          <Button type="submit">Submit</Button>
        </Form>
      </Segment>
    </div>
  )
}
export default UserRegistration