import { useDispatch, useSelector} from 'react-redux'
import * as UserApi from '../api/user-api'
import { LogoutUser } from '../actions/authentication-actions'
import Navigation from '../components/navigation'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { ClearTransactionPurpose } from '../actions/reference-data-actions'

function NavigationContainer() {
  const isAuthenticated = useSelector(state => state.auth)
  const [previouslyAuthenticated, setPreviouslyAuthenticated] = useState(isAuthenticated)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogout = () => {
    UserApi.logoutUser().then( () => console.info('User was logged out on server'))
    dispatch(LogoutUser())
    dispatch(ClearTransactionPurpose())
  }

  if(previouslyAuthenticated && !isAuthenticated){
    setPreviouslyAuthenticated(isAuthenticated)
    navigate('/home')
  }else if(!previouslyAuthenticated && isAuthenticated){
    setPreviouslyAuthenticated(isAuthenticated)
  }

  return <Navigation handleLogout = {handleLogout} isAuthenticated={isAuthenticated}/>
}

export default NavigationContainer