import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import AccountOverviewParentContainer from '../../containers/dashboard/account-overview-parent'
import AccountDetailsContainer from '../../containers/dashboard/account-details'
import { LoadTransactionPurpose } from '../../actions/reference-data-actions'

const Dashboard = () => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(
    () => { dispatch(LoadTransactionPurpose()) }
  , [])

  const isParentOrFamilyAdmin = user.roles !== undefined && ( user.roles.includes("FamilyAdmin") || user.roles.includes("Parent"))
  const viewComponent = isParentOrFamilyAdmin ? <AccountOverviewParentContainer/> : <AccountDetailsContainer/>

  return (
    <div>
      {viewComponent}
    </div>
  )
}

export default Dashboard