import React, { useEffect, useState } from 'react'
import {
  Button,
  Container, Divider,
  Form,
  Grid,
  Icon,
  Image,
  Input, Message,
} from 'semantic-ui-react'
import Logo from '../../assets/logo.png'
import { Link, useNavigate } from 'react-router-dom'


const Authentication = (props) =>{
  const { handleSubmit, loggedInUser, isAuthenticated } = props
  const [triedAuthentication, setTriedAuthentication] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if(isAuthenticated){
      navigate("/dashboard")
    }
  }, [isAuthenticated])

  const handleSubmitLocal = (event) => {
    handleSubmit(event)
    setTimeout(() => setTriedAuthentication(true), 1000)
  }

  // FORM STUFF
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const forgotPasswordLink = <Link to="/forgot">
    Forgotten password?
  </Link>

  const error = triedAuthentication && loggedInUser.authStatus !== 'SUCCESS' ? <Message negative> Error while logging in. {loggedInUser.authMessage}</Message> : <div/>

  const authenticationForm = <Container>
    {error}
    <Form onSubmit={handleSubmitLocal}>
      <Form.Field>
        <Input name="username" placeholder="email or username"/>
      </Form.Field>
      <Form.Field>
        <Input
          icon={<Icon name={showPassword ? "eye slash": "eye"} link onClick={toggleShowPassword}/>}
          type={!showPassword ? "password": "text"}
          name="password"
          placeholder="password" />
      </Form.Field>
      <Form.Field>
        <Button fluid color="green" type="submit" >LOG IN</Button>
      </Form.Field>
      <Form.Field>
        <div style={{textAlign:"center"}}>{forgotPasswordLink}</div>
      </Form.Field>
      <Divider/>
      <Button as={Link} to={"/registration"} fluid color="facebook">Create new account</Button>
    </Form>
  </Container>


  return (<div style={{textAlign:'center', marginTop:"10em"}}>
      <Grid verticalAlign={"middle"}>
        <Grid.Column width={5} floated={"right"}>
          <Grid.Row textAlign="center" verticalAlign={"middle"}>
            <Image src={Logo} size="small"/>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={5} floated={"left"}>
          {authenticationForm}
        </Grid.Column>
      </Grid>
    </div>
  )
}


export default Authentication