import { Route, Routes } from 'react-router-dom'

import UserRegistrationContainer from './containers/login/user-registration-container'
import Dashboard from './components/dashboard'
import AuthenticationContainer from './containers/login/authentication-container'
import Home from './components/home'
import SettingsView from './components/settings/settings-view'
import PasswordResetContainer from './containers/login/password-reset'
import ActionContainer from './containers/action/action-container'
import FamilyManagementContainer from './containers/family/family-management-v2'
const routes = <Routes>
  <Route path="/" exact element={<Home/>}/>
  <Route path="/home" exact element={<Home/>}/>
  <Route path="/dashboard" element={<Dashboard/>}/>
  <Route path="/login" element={<AuthenticationContainer/>}/>
  <Route path="/family" element={<FamilyManagementContainer/>}/>
  <Route path="/logout" element={<Home/>}/>
  <Route path="/settings" element={<SettingsView/>}/>
  <Route path="/registration" element={<UserRegistrationContainer/>}/>
  <Route path="/forgot" element={<PasswordResetContainer/>}/>
  <Route path="/action" element={<ActionContainer/>}/>
</Routes>

export default routes