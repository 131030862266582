import CompletePasswordReset from '../../containers/action/complete-password-reset'
import { useLocation } from 'react-router-dom'

const ActionContainer = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const mode = searchParams.get('mode')

  switch (mode) {
    case 'resetPassword':
      return <CompletePasswordReset/>
    default:
      return <div/>
  }
}

export default ActionContainer