import React from 'react'
import AddPosting from '../../components/dashboard/add-posting'
import {
  ClearCreatedPostings,
  CreatePosting,
} from '../../actions/posting-actions'
import { useDispatch, useSelector } from 'react-redux'

const AddPostingContainer = (props) => {
  const dispatch = useDispatch()
  const posting = useSelector( store => store.posting.created )
  const clearPostingCreation = () => { dispatch(ClearCreatedPostings())}

  const createPosting = (posting) => { dispatch(CreatePosting(posting)) }

  return <AddPosting {...props} createPosting={createPosting} posting={posting} clearPostingCreation={clearPostingCreation}/>
}

export default AddPostingContainer