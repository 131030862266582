import * as UserApi from './api/user-api'
import { store } from './state/store'
import { USER_AUTHENTICATED_CHECK } from './actions/authentication-actions'


const intervalId = setInterval(() => {
  UserApi.checkIfAuthenticated().then(
    res => res.status === 200 ? store.dispatch({
      type: USER_AUTHENTICATED_CHECK,
      payload: true
    }) :
      store.dispatch({
        type: USER_AUTHENTICATED_CHECK,
        payload: false
      })


  )
}, 60000);

export const stopBackgroundTask = () => {
  clearInterval(intervalId);
};