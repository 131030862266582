import { useDispatch} from 'react-redux'
import { AddFamily } from '../../actions/family-actions'
import SetupFamily from '../../components/family/setup-family'

const SetupFamilyContainer = () => {
  const dispatch = useDispatch()
  const handleSubmit = (familyName) => dispatch(AddFamily(familyName))
  return <SetupFamily handleSubmit = {handleSubmit} />
}

export default SetupFamilyContainer