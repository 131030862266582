import React, { useEffect, useState } from 'react'
import './account-component.css';
import { Card, Header, Image, Table } from 'semantic-ui-react'
import './account-component.css'
import { BASE_URL } from '../../api/common'
import axiosInstance from '../../api/base-api'
function AccountComponent({ account }) {
  const {  balance, accountOwner } = account
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const response = await axiosInstance.get(`${BASE_URL}/person/avatar?personId=${accountOwner.id}`, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'image/jpeg' });
      const objectUrl = URL.createObjectURL(blob);

      setImgSrc(objectUrl);
    };

    fetchImage().then(r => console.log(r))
  }, [accountOwner.id]);

  return (
    <Card fluid linked>
      <Card.Content>
        <div className="account-view">
        <Header as="h2" textAlign="left">
          <Image circular floated="left" size="mini" src={imgSrc} />
          {accountOwner.firstName}
        </Header>
        <Table basic={'very'} className="account-table">
          <Table.Body>
            <Table.Row>
              <Table.Cell className="account-cell">Balance</Table.Cell>
              <Table.Cell>{`$${balance.toFixed(2)}`}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        </div>
      </Card.Content>
    </Card>
  )
}
export default AccountComponent