import { BASE_URL } from './common'
import { getAuth, signInWithEmailAndPassword, } from 'firebase/auth'
import axiosInstance  from './base-api'
export async function loginUser (userDetails) {
  const auth = getAuth()
  const credentials = await signInWithEmailAndPassword(auth, userDetails.username, userDetails.password)

  // TODO move this inside the interceptor so that we get the refreshed token if needed, something like
  /*
  firebase.auth().currentUser.getIdToken(/ forceRefresh / true)
  .then(function(idToken) {

  }).catch(function(error) {

});
   */
  // const token = await credentials.user.getIdToken()
  axiosInstance.interceptors.request.use(
    async config => {
      const token = await getAuth().currentUser.getIdToken(false)

      config.headers.authorization = `Bearer ${token}`
      return config
    },
    error => {
      return Promise.reject(error)
    })

  return axiosInstance(BASE_URL + '/auth', {
    method: 'get',
    headers: { 'Content-Type': 'application/json'},
    responseType: 'json',
  }).then(
    (res) => { return res.data },
    (err) => { throw err },
  )
}

export async function logoutUser () {
  return axiosInstance(BASE_URL + '/auth/logout', {
    method: 'get',
    credentials: 'include',
  })
}

export async function getCurrentUser () {
  return axiosInstance(BASE_URL + '/auth/get-current-user', {
    method: 'get',
    responseType: 'json',
  })
    .then(
      (res) => { return res.data },
      err => { throw err },
    )
}

export async function checkIfUsernameExists (usernameRequest) {
  let payload = JSON.stringify(usernameRequest)
  return axiosInstance(BASE_URL + '/registration/user-exists', {
    method: 'post',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'text',
  })
  .then(
    res => { return res.data },
    err => { return { ...usernameRequest, 'exists': false, 'message': 'Unable to connect to service to check' } },
  )
}

export async function checkIfEmailExists (emailRequest) {
  let payload = JSON.stringify(emailRequest)
  return axiosInstance(BASE_URL + '/registration/email-exists', {
    method: '[pst]',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'text',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function checkIfAuthenticated () {
  return axiosInstance(BASE_URL + '/auth/authenticated', {
    method: 'get',
    responseType: 'text',
  }).then(
    (res) => { return res },
    err => { throw err },
  )
}

export async function registerUser (user) {
  let payload = JSON.stringify(user)
  return axiosInstance(BASE_URL + '/registration/register', {
    method: 'post',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function addUser (user, avatar) {
  let avatarParam = avatar === undefined || avatar === null ? null : {
    type: avatar.type,
    data: arrayBufferToBase64(avatar.data),
  }

  let addUserRequest = {
    'person': user,
    'avatar': avatarParam,
  }
  let payload = JSON.stringify(addUserRequest)

  return axiosInstance(BASE_URL + '/person', {
    method: 'post',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function updateUser (user, avatar) {
  let avatarParam = avatar === undefined || avatar === null ? null : {
    type: avatar.type,
    data: arrayBufferToBase64(avatar.data),
  }

  let modifyUserRequest = {
    'person': user,
    'avatar': avatarParam,
  }

  let payload = JSON.stringify(modifyUserRequest)
  return axiosInstance(BASE_URL + '/person', {
    method: 'put',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

function arrayBufferToBase64 (arrayBuffer) {
  let binary = ''
  const bytes = new Uint8Array(arrayBuffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

export async function deleteUser (id) {
  return axiosInstance(BASE_URL + '/person?id=' + id, {
    method: 'delete',
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function changePassword (passwordChangeRequest) {
  let payload = JSON.stringify(passwordChangeRequest)
  return axiosInstance(BASE_URL + '/auth/change', {
    method: 'post',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function startResetPassword (passwordResetRequest) {
  let payload = JSON.stringify(passwordResetRequest)
  return axiosInstance(BASE_URL + '/auth/reset-password', {
    method: 'post',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}