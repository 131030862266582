import React, { useEffect, useState } from 'react'
import Footer from '../components/footer'

import * as RefDataApi from '../api/reference-data-api'

const FooterContainer = () => {
  const [version, setVersion] = useState('Unknown')
  useEffect(
    () => { RefDataApi.getVersion().then( version => setVersion(version)) }, []
  )

  return <Footer appVersion={version} />
}

export default FooterContainer