import { BASE_URL } from './common'
import axiosInstance from './base-api'

export async function createPosting(posting){
  let payload = JSON.stringify(posting)
  return  axiosInstance(BASE_URL + '/posting', {
    method: 'POST',
    data: payload,
    headers: { 'Content-Type': 'application/json' }
  })
  .then(
    (res) => { return res.data },
    err => { throw err }
  )
}

export async function deletePosting(id) {
  return axiosInstance(BASE_URL + '/posting?id=' + id, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function loadPosting(accountId, asOf) {
  let asOfParam = ''
  if(asOf !== undefined){
    asOfParam = '&asOf=' + asOf
  }

  return axiosInstance(BASE_URL + '/posting?accountId=' + accountId + asOfParam, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function createdPostingSchedule(posting){
  let payload = JSON.stringify(posting)
  return  axiosInstance(BASE_URL + '/posting/scheduled', {
    method: 'POST',
    data: payload,
    headers: { 'Content-Type': 'application/json' }
  })
  .then(
    (res) => { return res.data },
    err => { throw err }
  )
}

export async function loadPostingSchedule(accountId){
  return axiosInstance(BASE_URL + '/posting/scheduled?accountId=' + accountId, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function deletePostingSchedule(id){
  return axiosInstance(BASE_URL + '/posting/scheduled?id=' + id, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}