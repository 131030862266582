import React from 'react'
import {useDispatch, useSelector } from 'react-redux'
import { LoadFamily } from '../../actions/family-actions'
import FamilyManagement from '../../components/family/family-management'

const FamilyManagementContainer = () => {
  const dispatch = useDispatch()
  const user = useSelector( state => state.user )
  const { payload, errors, loadingComplete } = useSelector( state => state.family )

  const loadFamily = () => { // noinspection JSCheckFunctionSignatures
    dispatch(LoadFamily(user.familyId)) }

  return <FamilyManagement loadFamily={loadFamily} family={payload} loadingComplete={loadingComplete} errors = {errors} />

}

export default FamilyManagementContainer