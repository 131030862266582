import React, { useState } from 'react'
import {
  Button,
  Container,
  Form, Grid,
  Icon,
  Input, Message,
} from 'semantic-ui-react'
import { PasswordChangeRequest } from '../../model/password-change-request'
import { useEffect } from 'react'

const PasswordSettings = props => {
  const { handleSubmit, passwordChange, cleanupPasswordChange } = props
  useEffect( () => {
    return () => {
      // fires on unmount
      cleanupPasswordChange()
    }
  },[])

  const [showPassword, setShowPassword] = useState(
    { old: false, new: false, confirm: false })
  const toggleShowPassword = (key) => {
    const newState = { ...showPassword }
    newState[key] = !showPassword[key]
    setShowPassword(newState)
  }

  const handleSubmitLocal = (event) => {

    const formData = new FormData(event.target)
    const passwordChangeRequest = new PasswordChangeRequest(
      formData.get("old"),
      formData.get("new"),
      formData.get("confirm")
      )
    handleSubmit(passwordChangeRequest)
  }

  let message = <div/>
  if(passwordChange !== undefined && passwordChange.result ){
    message = <Message positive>
      <Message.Header>Your password was successfully changed</Message.Header>
      <Message.Content>You can leave the window and use the new password going forward</Message.Content>
    </Message>

  }else if(passwordChange !== undefined && !passwordChange.result){
    message = <Message negative>
      <Message.Header>There was a problem while changing your password</Message.Header>
      <Message.Content>{passwordChange.error}</Message.Content>
    </Message>
  }

  return (
    <div style={{ maxWidth: '600%', padding:'0em 10em 5em 5em'}} >
      <Grid>
        <Grid.Row centered>
          <Container>
            Please enter your old password for confirmation and then set a new password. Remember password has to be between 6 and 20 characters
          </Container>
        </Grid.Row >
        <Grid.Row centered>
          {message}
        </Grid.Row>
        <Grid.Row centered>
        <Form onSubmit={handleSubmitLocal}>
          <Form.Field  required>
            <label>Current Password</label>
            <Input
              icon={<Icon name={!showPassword.old ? 'eye slash' : 'eye'} link
                          onClick={(event) => toggleShowPassword('old')}/>}
              type={!showPassword.old ? 'password' : 'text'}
              name="old"
              placeholder="Old Password"/>
          </Form.Field>
          <Form.Group widths={'equal'}>
          <Form.Field  required>
            <label>New Password</label>
            <Input
              icon={<Icon name={!showPassword.new ? 'eye slash' : 'eye'} link
                          onClick={(event) => toggleShowPassword('new')}/>}
              type={!showPassword.new ? 'password' : 'text'}
              name="new"
              placeholder="New Password"/>
          </Form.Field>

          <Form.Field  required>
            <label>New Password Confirmation</label>
            <Input
              icon={<Icon name={!showPassword.confirm ? 'eye slash' : 'eye'}
                          link
                          onClick={(event) => toggleShowPassword('confirm')}/>}
              type={!showPassword.confirm ? 'password' : 'text'}
              name="confirm"
              placeholder="New Password Confirmation"/>
          </Form.Field>
          </Form.Group>
          <Form.Field>
            <Button color="green" type="submit">CONFIRM</Button>
          </Form.Field>
        </Form>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default PasswordSettings