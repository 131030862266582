import React from 'react';
import './account-component.css';
import { Card, Icon, Table } from 'semantic-ui-react'
import './spent-overview-component.css'

function SpentOverviewComponent(props) {
  const {  totalSpent, totalReceived } = props

  const delta = totalReceived - totalSpent
  const deltaView = delta < 0 ? <Table.Cell className={'total'}><b style={{color:'red'}}>{`$${delta.toFixed(2)}`}</b><Icon color={'red'} name={'exclamation circle'}/></Table.Cell> :
    <Table.Cell className={'total'}><b >{`$${delta.toFixed(2)}`}</b></Table.Cell>

  return (
    <Card fluid linked>
      <Card.Content>
        <div className="overview">
        <Table basic={'very'}>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Received</Table.Cell>
              <Table.Cell>{`$${totalReceived.toFixed(2)}`}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{borderTop: 'none'}}>Spent</Table.Cell>
              <Table.Cell style={{borderTop: 'none'}}>{`$${totalSpent.toFixed(2)}`}</Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Footer fullWidth >
            <Table.Row>
              <Table.Cell  className={'total'}>Total:</Table.Cell>
              {deltaView}
            </Table.Row>
          </Table.Footer>
        </Table>
        </div>
      </Card.Content>
    </Card>
  )
}
export default SpentOverviewComponent