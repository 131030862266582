import React, { useState } from 'react'
import PasswordSettings from '../../containers/settings/password-settings'
import { Menu } from 'semantic-ui-react'
import TransactionPurposeSettingsContainer
  from '../../containers/settings/transaction-purpose-settings'

const SettingsView = () => {
  const [activeItem, setActiveItem] = useState('password')
  let activeView

  switch(activeItem){
    case 'password':
      activeView = <PasswordSettings/>
      break
    case 'transaction-purpose':
      activeView = <TransactionPurposeSettingsContainer />
      break
    default:
      activeView = <div>TBD</div>
    }

  return (<div>
      <Menu pointing secondary>
        <Menu.Item name='password' active={activeItem === 'password'} onClick={ () => setActiveItem('password') } />
        <Menu.Item name='transaction-purpose' active={activeItem === 'transaction-purpose'} onClick={ () => setActiveItem('transaction-purpose') } />
      </Menu>
      {activeView}
    </div>
  )
}

export default SettingsView