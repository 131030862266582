import {
  CURRENT_USER_LOADED_FULFILLED,
  LOGOUT_USER,
  USER_SIGNED_IN_FULFILLED,
  USER_SIGNED_IN_REJECTED
} from '../../actions/authentication-actions'


export default function userReducer (state = {}, action) {
  switch (action.type) {
    case USER_SIGNED_IN_FULFILLED:
      return  action.payload
    case USER_SIGNED_IN_REJECTED:
      return {
        authMessage: "Server not available please try again later."
      }
    case CURRENT_USER_LOADED_FULFILLED:
      return  action.payload === undefined ? {} : action.payload
    case LOGOUT_USER:
      return {}
    default:
      return state
  }
}