import {
  CHANGE_PASSWORD_FULFILLED,
  RESET_PASSWORD_FULFILLED,
} from '../../actions/user-actions'
import { CLEANUP_PASSWORD_CHANGE_STATE } from '../../actions/settings-actions'
import { LOGOUT_USER } from '../../actions/authentication-actions'

export default function settingsReducer (state = {}, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_FULFILLED:
      return {
        ...state,
        passwordChange: {
          result: action.payload.result,
          error: action.payload.errors,
        },
      }

    case RESET_PASSWORD_FULFILLED: {
      return {
        ...state,
        passwordReset: {
          result: action.payload.result,
          error: action.payload.error
        }
      }
    }

    case CLEANUP_PASSWORD_CHANGE_STATE:
      return {
        ...state,
        passwordChange: undefined,
      }
    case LOGOUT_USER:
      return {}
    default:
      return state
  }
}