import React from 'react'
import ModifyUser from '../../components/family/modify-user'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateUserInFamily } from '../../actions/family-actions'
import {
  CheckUsernameExists,
} from '../../actions/authentication-actions'

const ModifyUserContainer = (props) => {
  const {toggleModal, context, person} = props
  const dispatch = useDispatch()
  const registration = useSelector(state => state.registration)
  const referenceData = useSelector(state => state.referenceData)

  const updateUserInFamily = (newUser, avatarData) => dispatch(UpdateUserInFamily(newUser, avatarData))
  const checkUserNameExists = (username) => dispatch(CheckUsernameExists(username))

  return <ModifyUser submit={updateUserInFamily}
                     person={person}
                     registration={registration}
                     referenceData={referenceData}
                     toggleModal={toggleModal}
                     checkUserNameExists={checkUserNameExists}/>
}

export default ModifyUserContainer