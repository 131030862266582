import {
  CHECK_USERNAME_EXISTS_FULFILLED,
  CHECK_EMAIL_EXISTS_FULFILLED,
  REGISTER_USER_FULFILLED
} from '../../actions/authentication-actions'

const initialState = {}

export default function familyReducer (state = initialState, action) {

  switch (action.type) {
    case CHECK_USERNAME_EXISTS_FULFILLED:
      return {
        ...state,
        usernameCheck: action.payload
      }
    case CHECK_EMAIL_EXISTS_FULFILLED:
      return {
        ...state,
        emailCheck: action.payload
      }
    case REGISTER_USER_FULFILLED:
      return {
        ...state,
        signup: action.payload
      }
    default:
      return state
  }
}