import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  Container,
  Form,
  Grid,
  Input,
  List,
  Message,
  Select,
} from 'semantic-ui-react'
import { PostingSchedule } from '../../model/account-models'
import { DatePicker } from '@mui/x-date-pickers'

const AddPostingSchedule = (props) => {
  const { account, createScheduledPosting, referenceData, postingState, clearPostingCreation } = props
  const [amount, setAmount] = useState(0)
  const [type, setType] = useState('credit')
  const [date, setDate] = useState(dayjs())
  const [purpose, setPurpose] = useState('')
  const [otherPurpose, setOtherPurpose] = useState('')
  const [errors, setErrors] = useState([])
  const [frequency, setFrequency] = useState('monthly')

  useEffect( () => {
    return () => {
      clearPostingCreation()
    }
  },[])

  if (account === undefined) {
    return <Message negative> No account found to add transactions to book
      against. Please reach out to Support</Message>
  }
  console.debug('=== creating schedule posting for account owner', account.accountOwner.id, ' name: ', account.accountOwner.firstName)

  const handlePurposeChange = (event, { value }) => {
    setPurpose(value)
    if (value === 'other') {
      setOtherPurpose('')
    }
  }

  const handleOtherPurposeChange = (event) => {
    setOtherPurpose(event.target.value)
  }

  const validatePosting = (posting) =>{
    const validationResult = []

    if(posting.amount === 0 || isNaN(posting.amount) || posting.amount < 0){
      validationResult.push('Please enter a valid amount greater than 0')
    }

    if(date === undefined){
      validationResult.push('Please select a valid date before submitting the booking')
    }

    setErrors(validationResult)
    return errors.length === 0;
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const scheduledPosting = new PostingSchedule(account.id, date.format("YYYY-MM-DD"), frequency, type, amount,
      purpose === 'other' ? otherPurpose : purpose)
    const validData = validatePosting(scheduledPosting)
    if(!validData){
      return
    }
    createScheduledPosting(scheduledPosting)
  }

  const purposeOptions = referenceData.transactionPurpose.map(
    (purpose) => ({ key: purpose.name, value: purpose.name, text: purpose.name }))
  purposeOptions.push({ key: 'other', value: 'other', text: 'Other' })

  // TODO this should come from reference data
  const frequencyOptions = [
    {key: 'daily', value:'daily', text: 'Every Day'},
    {key: 'weekly', value:'weekly', text: 'Every Week'},
    {key: 'biweekly', value:'biweekly', text: 'Bi-Weekly'},
    {key: 'monthly', value:'monthly', text: 'Every Month'},
  ]

  // TODO refactor as this entire code is duplicated. This should be moved out into common logic for add-posting.jsx and add-scheduled-posting.jsx
  // noinspection JSValidateTypes
  const displayedErrors = errors.length === 0 && (postingState === undefined || postingState.errors === undefined || postingState.errors.length ===0 )  ? '' : (
    <Message negative>
      <List divided relaxed>
        { errors.map( (error) => <List.Item>{error}</List.Item>)}
        { postingState !== undefined ? postingState.errors.map( (error) => <List.Item>{error}</List.Item>) : ''}
      </List>
    </Message>)

  // const serverError = posting !== undefined && posting.errors !== undefined ? <Message negative attached content={ posting.errors }/> : <div/>
  const success = (postingState !== undefined && postingState.errors !== undefined && postingState.errors.length === 0) ?
    <Message success content={"Your booking was created"}/> : <div/>

  const checkIfAmountIsValid = (amount) => {
    const decimalIndex = amount.indexOf('.')
    return !(decimalIndex !== -1 && amount.substring(decimalIndex + 1).length >= 2)
  }

  return (
    <Grid>
      <Grid.Row centered>
        {success}
        {displayedErrors}
      </Grid.Row>
      <Grid.Row >
        <Container textAlign={'left'}>
          You can configure your family's most common transaction purposes under Settings as a Family Admin. Once completed you can simply select going forwards
        </Container>
      </Grid.Row>
      <Grid.Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group widths={'equal'}>
            <Form.Field
              control={Input}
              label="Amount"
              value={amount}
              icon={'dollar'}
              type={'number'}
              onKeyPress={ (event) => checkIfAmountIsValid(event.target.value) ? null : event.preventDefault() }
              onChange={(event) => setAmount(event.target.value === '' ? '' : parseFloat(event.target.value))}
            />
            <Form.Field
              control={Select}
              label="Type"
              options={[
                { key: 'debit', value: 'debit', text: 'Pay' },
                { key: 'credit', value: 'credit', text: 'Receive' },
              ]}
              value={type}
              onChange={(event, { value }) => setType(value)}
            />
            <Form.Field
              control={Select}
              label="Purpose"
              options={purposeOptions}
              value={purpose}
              onChange={handlePurposeChange}
            />
            {purpose === 'other' && (
              <Form.Field
                control={Input}
                label="Other Purpose"
                type="text"
                value={otherPurpose}
                onChange={handleOtherPurposeChange}
              />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Start Date</label>
              <DatePicker onChange={value => setDate(value)} value={date}/>
            </Form.Field>

            <Form.Field
              control={Select}
              label="Frequency"
              options={frequencyOptions}
              value={frequency}
              onChange={ (event, { value }) =>  setFrequency(value) }
            />
          </Form.Group>
          <Form.Button primary>Submit</Form.Button>
        </Form>
      </Grid.Row>
    </Grid>
  )
}

export default AddPostingSchedule