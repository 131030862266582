import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AccountOverviewParent
  from '../../components/dashboard/account-overview-parent'
import { GetFamilyAccounts } from '../../actions/account-actions'
import {
  CLEAR_POSTING_STATE, DeletePosting,
} from '../../actions/posting-actions'

const AccountOverviewParentContainer = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.account.familyAccounts)
  const referenceData = useSelector(state => state.referenceData)
  const loadFamilyAccount = () => { dispatch(GetFamilyAccounts()) }
  const clearPostingState = (posting) => { dispatch({ type: CLEAR_POSTING_STATE }) }

  const deletePosting = (id) => { dispatch(DeletePosting(id))}

  return (
    <AccountOverviewParent data={data}
                           loadFamilyAccount={loadFamilyAccount}
                           deletePosting={deletePosting}
                           clearPostingState={clearPostingState}
                           referenceData={referenceData}/>
  )
}

export default AccountOverviewParentContainer