import React, {useState} from 'react'
import {
  Button,
  Container,
  Divider,
  Form, Grid,
  Image,
  Input,
} from 'semantic-ui-react'
import sadFaceImg from '../../assets/sad-face.png'


const SetupFamily = ({ handleSubmit }) =>{
  const [ familyName, setFamilyName] = useState(undefined)
  const handleChange = (e, { value }) => setFamilyName(value)

  return (
    <div className="center" style={{width:'90%'}}>
    <Grid>
    <Grid.Row>
      {/*<div style={{display: 'flex'}}>*/}
      <Image src={ sadFaceImg } size='tiny'/>
      <Container text >How sad... it seems you don't have a family setup yet. Let's get this fixed</Container>
       {/*</div>*/}
    </Grid.Row>
      <Grid.Row>
      <Form size="large">
        <Divider/>
        <Form.Field inline>
          <label>Family Name</label>
          <Input name='name' placeholder="Family Name" onChange={ handleChange }/>
          <Divider/>
          <Button type="button" onClick={() => handleSubmit(familyName)}>Create</Button>
        </Form.Field>
      </Form>
      </Grid.Row>
    </Grid>
    </div>
  )
}

export default SetupFamily