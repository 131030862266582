import * as  UserApi from '../api/user-api'

export const CHANGE_PASSWORD = 'user/change-password'
export const RESET_PASSWORD = 'user/reset-password'
export const CHANGE_PASSWORD_FULFILLED = 'user/change-password_FULFILLED'
export const RESET_PASSWORD_FULFILLED = 'user/reset-password_FULFILLED'

export function ChangeUserPassword(passwordChangeRequest){
  return {
    type: CHANGE_PASSWORD,
    payload: UserApi.changePassword(passwordChangeRequest)
  }
}

export function ResetUserPassword(passwordResetRequest){
  return {
    type: RESET_PASSWORD,
    payload: UserApi.startResetPassword(passwordResetRequest)
  }
}