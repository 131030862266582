import React from 'react'
import './navigation.css'
import { Image, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo-navbar.png'

function Navigation (props) {
  const {handleLogout, isAuthenticated} = props

  const loggedInUserMenu = [
    <Menu.Item key="dashboard" as={Link} to="/dashboard" name="Dashboard" icon="dashboard"/>,
    <Menu.Item key="family" as={Link} to="/family" name="Family" icon="users"/>,
    <Menu.Item key="settings" as={Link} to="/settings" name="Settings" icon="setting"/>
  ]

  const loginButton = <Menu.Item className="right" as={Link} to="/login" ><b>Login</b></Menu.Item>
  const logoutButton = <Menu.Item className="right" as={Link} to="/logout" onClick={handleLogout}><b>Logout</b></Menu.Item>

  const menu =
    <div className="navigation">
      <Menu inverted attached>
        <Menu.Item><Image src={Logo} size={"mini"}/></Menu.Item>
        <Menu.Item as={Link} to="/"><b>My Pocket Money</b></Menu.Item>
        { isAuthenticated && loggedInUserMenu }
        { isAuthenticated && logoutButton }
        { !isAuthenticated && loginButton }
      </Menu>
    </div>

  return (
    menu
  )
}

export default Navigation