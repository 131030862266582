import * as PostingApi from '../api/posting-api'

export const CREATE_POSTING = 'acc/create-posting'
export const CREATE_POSTING_FULFILLED = 'acc/create-posting_FULFILLED'
export const CREATE_SCHEDULED_POSTING = 'acc/create-scheduled-posting'
export const CREATE_SCHEDULED_POSTING_FULFILLED = 'acc/create-scheduled-posting_FULFILLED'
export const LOAD_SCHEDULED_POSTING = 'acc/load-scheduled-posting'
export const LOAD_SCHEDULED_POSTING_FULFILLED = 'acc/load-scheduled-posting_FULFILLED'

export const LOAD_POSTINGS = 'acc/load-postings'
export const LOAD_POSTINGS_FULFILLED = 'acc/load-postings_FULFILLED'

export const DELETE_SCHEDULED_POSTING = 'acc/load-scheduled-posting'
export const DELETE_SCHEDULED_POSTING_FULFILLED = 'acc/load-scheduled-posting_FULFILLED'

// TODO we use the same value as this will trigger the correct reducer anyway. The response is the same
// not sure if that is a good idea but maybe fix later
export const DELETE_POSTING = 'acc/TBD'

export const CLEAR_POSTING_STATE = 'acc/clear-posting-state'
export const CLEAR_POSTING_CREATION_STATE = 'acc/clear-posting-creation-state'

export function CreatePosting(posting){
  return {
    type: CREATE_POSTING,
    payload: PostingApi.createPosting(posting)
  }
}

export function CreatePostingSchedule(scheduledPosting){
  return {
    type: CREATE_SCHEDULED_POSTING,
    payload: PostingApi.createdPostingSchedule(scheduledPosting)
  }
}

export function LoadPostingSchedule(accountId){
  return {
    type: LOAD_SCHEDULED_POSTING,
    payload: PostingApi.loadPostingSchedule(accountId)
  }
}

export function DeletePostingSchedule(id){
  return {
    type: DELETE_SCHEDULED_POSTING,
    payload: PostingApi.deletePostingSchedule(id)
  }
}

export function DeletePosting(id){
  return {
    type: DELETE_POSTING,
    payload: PostingApi.deletePosting(id)
  }
}

export function LoadPostings(accountId, month){
  return {
    type: LOAD_POSTINGS,
    payload: PostingApi.loadPosting(accountId, month)
  }
}

export function ClearCreatedPostings() {
  return {
    type: CLEAR_POSTING_CREATION_STATE
  }
}