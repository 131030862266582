export class Family{
    constructor(name, members, id, createdAt){
        this.name = name
        this.members = members
        this.id = id
        this.createdAt = createdAt
    }
}

export class Person{
    constructor(firstName, lastName, dob, gender, roles, username, id){
        this.firstName = firstName
        this.lastName = lastName
        this.dob = dob
        this.gender = gender
        this.roles = roles
        this.username = username
        this.id = id
    }
}

export class MemberRegistration{
    constructor(firstName, lastName, roles, username, password){
        this.firstName = firstName
        this.lastName = lastName
        this.username = username
        this.password = password
        this.roles = roles
    }
}


export class Avatar {
    constructor (type, data) {
        this.type = type
        this.data = data
    }
}