export class PasswordChangeRequest {
  constructor(oldPassword, newPassword, newPasswordConfirm ){
    this.oldPassword = oldPassword
    this.newPassword = newPassword
    this.newPasswordConfirm = newPasswordConfirm
  }
}

export class PasswordResetRequest {
  constructor (username, email) {
    this.username = username
    this.email = email
  }
}