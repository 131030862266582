// noinspection JSCheckFunctionSignatures

import { useState } from 'react'
import { confirmPasswordReset, getAuth } from 'firebase/auth'
import { useLocation } from 'react-router-dom'
import CompletePasswordReset from '../../components/action/complete-password-reset'

const CompletePasswordResetContainer = (props) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const oobCode = searchParams.get('oobCode')

  const [message, setMessage] = useState(null)
  const handleSubmit = (event) => {
    event.preventDefault()
    const form = new FormData(event.target)
    const password = form.get('password')
    const passwordConfirmation = form.get('passwordConfirmation')
    if (password !== passwordConfirmation) {
      setMessage('Passwords do not match')
    } else {
      setMessage(null)
    }

    const auth = getAuth()
    confirmPasswordReset(auth, oobCode, password).then(() => {
      setMessage('Password reset successfully')
    }).catch((error) => {
      setMessage('Error while changing your password (Code:  ' + error.code + ' )')
    })
  }

  return <CompletePasswordReset message={message} handleSubmit={handleSubmit}/>
}

export default CompletePasswordResetContainer