import React, { useEffect, useState } from 'react'
import {
  Form,
  Grid,
  Message,
  Select,
  Input,
  List, Container,
} from 'semantic-ui-react'
import { Posting } from '../../model/account-models'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

const AddPosting = (props) => {
  const { account, createPosting, referenceData, posting, clearPostingCreation } = props

  const [amount, setAmount] = useState(0)
  const [type, setType] = useState('credit')
  const [date, setDate] = useState(dayjs())
  const [purpose, setPurpose] = useState('')
  const [otherPurpose, setOtherPurpose] = useState('')
  const [errors, setErrors] = useState([])

  useEffect( () => {
    return () => {
      clearPostingCreation()
    }
  },[])

  if (account === undefined) {
    return <Message negative> No account found to add transactions to book
      against. Please reach out to Support</Message>
  }
  console.debug('=== creating posting for account owner', account.accountOwner.id, ' name: ', account.accountOwner.firstName)

  const handlePurposeChange = (event, { value }) => {
    setPurpose(value)
    if (value === 'other') {
      setOtherPurpose('')
    }
  }

  const handleOtherPurposeChange = (event) => {
    setOtherPurpose(event.target.value)
  }

  const validatePosting = (posting) =>{
    const validationResult = []

    if(posting.amount === 0 || isNaN(posting.amount) || posting.amount < 0){
      validationResult.push('Please enter a valid amount greater than 0')
    }

    if(date === undefined){
      validationResult.push('Please select a valid date before submitting the booking')
    }

    setErrors(validationResult)
    return errors.length === 0;
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const posting = new Posting(account.id, date.format("YYYY-MM-DD"), type, amount,
      purpose === 'other' ? otherPurpose : purpose)
    const validData = validatePosting(posting)
    if(!validData){
      return
    }

    console.debug('Creating Posting:', posting)
    createPosting(posting)
  }

  const purposeOptions = referenceData.transactionPurpose.map(
    (purpose) => ({ key: purpose.name, value: purpose.name, text: purpose.name }))
  purposeOptions.push({ key: 'other', value: 'other', text: 'Other' })


  // noinspection JSValidateTypes
  const displayedErrors = errors.length === 0 && (posting === undefined || posting.errors === undefined || posting.errors.length ===0 )  ? '' : (
    <Message negative>
      <List divided relaxed>
        { errors.map( (error) => <List.Item>{error}</List.Item>)}
        { posting !== undefined ? posting.errors.map( (error) => <List.Item>{error}</List.Item>) : ''}
      </List>
    </Message>)

  // const serverError = posting !== undefined && posting.errors !== undefined ? <Message negative attached content={ posting.errors }/> : <div/>
  const success = (posting !== undefined && posting.errors !== undefined && posting.errors.length === 0) ?
    <Message success content={"Your booking was created"}/> : <div/>

  const checkIfAmountIsValid = (amount) => {
    const decimalIndex = amount.indexOf('.')
    return !(decimalIndex !== -1 && amount.substring(decimalIndex + 1).length >= 2)
  }

  return (
    <Grid>
      <Grid.Row centered>
        {success}
        {displayedErrors}
      </Grid.Row>
      <Grid.Row>
        <Container>
        You can configure your family's most common transaction purposes under Settings as a Family Admin. Once completed you can simply select going forwards
        </Container>
      </Grid.Row>
      <Grid.Row>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Field
              control={Input}
              label="Amount"
              value={amount}
              icon={'dollar'}
              type={'number'}
              onKeyPress={ (event) => checkIfAmountIsValid(event.target.value) ? null : event.preventDefault() }
              onChange={(event) => setAmount(event.target.value === '' ? '' : parseFloat(event.target.value))}
            />
            <Form.Field
              control={Select}
              label="Type"
              options={[
                { key: 'debit', value: 'debit', text: 'Pay' },
                { key: 'credit', value: 'credit', text: 'Receive' },
              ]}
              value={type}
              onChange={(event, { value }) => setType(value)}
            />
            <Form.Field>
              <label>Date</label>
              <DatePicker onChange={value => setDate(value)} value={date}/>
            </Form.Field>
            <Form.Field
              control={Select}
              label="Purpose"
              options={purposeOptions}
              value={purpose}
              onChange={handlePurposeChange}
            />
            {purpose === 'other' && (
              <Form.Field
                control={Input}
                label="Other Purpose"
                type="text"
                value={otherPurpose}
                onChange={handleOtherPurposeChange}
              />
            )}
          </Form.Group>
          <Form.Button primary>Submit</Form.Button>
        </Form>
      </Grid.Row>
    </Grid>
  )
}

export default AddPosting