import {
  CLEAR_USER_ACCOUNT,
  GET_FAMILY_ACCOUNTS_FULFILLED,
  GET_USER_ACCOUNT_FULFILLED,
} from '../../actions/account-actions'
import { LOGOUT_USER } from '../../actions/authentication-actions'


export default function accountReducer (state = {}, action) {
  switch (action.type) {
    case GET_FAMILY_ACCOUNTS_FULFILLED:
      return  action.payload === undefined ? {
        ...state,
        familyAccounts: []
      } : {
        ...state,
        familyAccounts: action.payload
      }
    case GET_USER_ACCOUNT_FULFILLED:
      return  action.payload === undefined ? {
        ...state,
        userAccount: {}
      } : {
        ...state,
        userAccount: action.payload
      }
    case CLEAR_USER_ACCOUNT:
      return {
        ...state,
        userAccount: undefined
      }
    case LOGOUT_USER:
      return {}
    default:
      return state
  }
}