import {
  CREATE_POSTING_FULFILLED,
  CREATE_SCHEDULED_POSTING_FULFILLED,
  LOAD_SCHEDULED_POSTING_FULFILLED,
  LOAD_POSTINGS_FULFILLED,
  CLEAR_POSTING_STATE,
  DELETE_SCHEDULED_POSTING,
  CLEAR_POSTING_CREATION_STATE,
} from '../../actions/posting-actions'
import { LOGOUT_USER } from '../../actions/authentication-actions'

const defaultState = {
  created: undefined,
  scheduledPostings: {},
  response: {}
}

export default function postingReducer (state = defaultState, action) {
  switch (action.type) {
    case CREATE_POSTING_FULFILLED:
      return  {
        ...state,
        created: action.payload
      }
    case CREATE_SCHEDULED_POSTING_FULFILLED:
      return  {
        ...state,
        created: action.payload
      }
    case LOAD_SCHEDULED_POSTING_FULFILLED:
      return {
        ...state,
        scheduledPostings: action.payload
      }
    case DELETE_SCHEDULED_POSTING:
      return {
        ...state,
        deletion: action.payload
      }
    case LOAD_POSTINGS_FULFILLED: {
      return {
        ...state,
        error: action.payload.errors,
        response: {
          range: action.payload.payload.first,
          postings: action.payload.payload.second
        }
      }
    }
    case CLEAR_POSTING_CREATION_STATE: {
      return {
        ...state,
        created: undefined
      }
    }
    case CLEAR_POSTING_STATE:
      return defaultState
    case LOGOUT_USER:
      return defaultState
    default:
      return state
  }
}