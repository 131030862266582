import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddUserToFamily} from '../../actions/family-actions'
import {
  CheckUsernameExists,
} from '../../actions/authentication-actions'
import AddUser from '../../components/family/add-user'

const AddUserContainer = (props) => {
  const {toggleModal, context, person} = props
  const dispatch = useDispatch()
  const registration = useSelector(state => state.registration)
  const referenceData = useSelector(state => state.referenceData)

  const addUserToFamily = (newUser, avatarData) => dispatch(AddUserToFamily(newUser, avatarData))
  const checkUsernameExists =  (username ) => dispatch(CheckUsernameExists(username) )

  return <AddUser submit={addUserToFamily }
                     registration={registration}
                     referenceData={referenceData}
                     toggleModal={toggleModal}
                    checkUsernameExists={checkUsernameExists}/>
}

export default AddUserContainer