import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Dimmer, Grid, Loader, Segment, Table } from 'semantic-ui-react'
import AccountComponent from './account-component'
import SpentOverviewComponent from './spent-overview-component'
import { DatePicker } from '@mui/x-date-pickers'

const AccountDetailsComponent = (props) => {
  const { account, postings, range, error, loadAccount, deletePosting, loadPostings, clearUserAccount } = props
  const defaultDate = range === undefined || range.from === undefined ? dayjs() : dayjs(range.from)
  const [date, setDate] = useState(defaultDate)

  useEffect(() => {
      // TODO this needs to change as its a hack. but we would need to reset the user account in the redux state so that we always trigger a reload when the component is opened again.
      if (postings === undefined) {
        loadAccount()
        if (account !== undefined) {
          loadPostings()
        }
      } else {
        if (account !== undefined && account.userAccount === undefined) {
          loadAccount()
        }
      }
    }, [account, postings])

  useEffect(() => {
    return () => {
      console.debug('Resetting Redux User Account information')
      clearUserAccount()
    }
  }, [])

  const loadForDate = (date) => {
    console.debug('Loading postings for ' + date)
    setDate(date)
    loadPostings(date.format('YYYY-MM-DD'))
    loadAccount(date.format('YYYY-MM-DD'))   // when enabling this the page stays dimmed. need to debug
  }

  const triggerDelete = (id) => {
    deletePosting(id)
    setTimeout(() => loadPostings(), 200) // not sure this is a good idea but let's see
  }

  if (error !== undefined) {
    return <div>This will show the error message </div>
  }

  if (account === undefined || postings === undefined) {
    return (
      <Dimmer active>
        <Loader content="Loading Account Details"/>
      </Dimmer>
    )
  }

  const totalReceived = postings.filter(posting => posting.type === 'credit').reduce((acc, posting) => acc + posting.amount, 0)
  const totalSpent = postings.filter(posting => posting.type === 'debit').reduce((acc, posting) => acc + posting.amount, 0)

  let postingRows = <div/>
  if (postings.length > 0) {
    postingRows = postings.map((posting, i) =>
      <Table.Row key={i}>
        <Table.Cell>
          {posting.date}
        </Table.Cell>
        <Table.Cell>
          {formatAmount(roundToTwo(posting.amount), posting.type)}
        </Table.Cell>
        <Table.Cell>
          {posting.purpose}
        </Table.Cell>
        <Table.Cell>
          {posting.createdBy}
        </Table.Cell>
        {deletePosting !== undefined ? <Table.Cell><Button icon={'trash'} onClick={() => triggerDelete(posting.id)}/></Table.Cell> : <Table.Cell/>}
      </Table.Row>,
    )
  }

  // const dateLabel = createDateLabel(range)
  return (
    <Grid>
      <Grid.Column width={4}>
        <AccountComponent account={account}/>
        <SpentOverviewComponent totalReceived={totalReceived} totalSpent={totalSpent}/>
      </Grid.Column>
      <Grid.Column width={10}>
        <Segment>
          <DatePicker views={['year', 'month']} openTo={'month'} onChange={value => loadForDate(value)} value={date} disableFuture={true}/>
          {/*</Label>*/}
          <Table padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell singleLine>Date</Table.HeaderCell>
                <Table.HeaderCell singleLine>Amount</Table.HeaderCell>
                <Table.HeaderCell singleLine>What was it</Table.HeaderCell>
                <Table.HeaderCell singleLine>Booked By</Table.HeaderCell>
                <Table.HeaderCell/>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {postingRows}
            </Table.Body>

          </Table>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

const formatAmount = (amount, type) => {
  if (type === 'credit') {
    return <span style={{ fontWeight: 'bold', color: 'green' }}>${amount}</span>
  } else {
    return <span style={{ fontWeight: 'bold', color: 'red' }}>-${amount}</span>
  }
}

const roundToTwo = (num) => {
  const rounded = Math.round((num + Number.EPSILON) * 100) / 100
  const truncated = Math.trunc(rounded * 100) / 100
  return truncated.toFixed(2)
}
export default AccountDetailsComponent