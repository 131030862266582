import React, { useState } from 'react'
import { Button, Icon, Input, Message, Popup, Table } from 'semantic-ui-react'

const TransactionPurposeSettings = (props) => {
  const {
    transactionPurpose,
    handleDelete,
    handleAdd,
    errors,
    userRoles,
  } = props
  const [newPurpose, setNewPurpose] = useState(undefined)
  const [validationError, setValidationError] = useState('')
  const isFamilyAdmin = userRoles.includes('FamilyAdmin')

  const generateTableRow = (transactionPurpose) => {
    const createdByIconName = transactionPurpose.createdBy === 'System'? 'computer': 'user'

    const deleteButton = transactionPurpose.createdBy !== 'System' ? <Button icon={'trash'} onClick={() => handleDelete(transactionPurpose.id)}/> : ''



    return (
      <Table.Row key={transactionPurpose.id}>
        <Table.Cell>{transactionPurpose.name}</Table.Cell>
        <Table.Cell><Popup content={transactionPurpose.createdBy} trigger={<Icon name={createdByIconName}/>}/></Table.Cell>
        {isFamilyAdmin ? <Table.Cell>{deleteButton}</Table.Cell> : '' }
    </Table.Row>
    )
  }


  const checkAndHandleAdd = () => {
    if (newPurpose === undefined || newPurpose === '') {
      setValidationError('Please enter a valid purpose')
    } else {
      setValidationError('')
      handleAdd(newPurpose)
      setNewPurpose('')
    }
  }

  const body = transactionPurpose.sort( (left, right) => {
    left.name.localeCompare(right.name)
  }).map( value => generateTableRow(value) )
  body.push(
    <Table.Row key={'-1'}>
      <Table.Cell><Input transparent placeholder={'New Value'} name={'newValue'}
                         value={newPurpose} onChange={(event) => setNewPurpose(
        event.target.value)}/></Table.Cell>
      <Table.Cell></Table.Cell>
      {isFamilyAdmin
        ? <Table.Cell><Button icon={'plus circle'}
                              onClick={() => checkAndHandleAdd()}/></Table.Cell>
        : ''}
    </Table.Row>
  )


  return (
    <div style={{ maxWidth: '60%' }}>
      {validationError === '' ? '' : <Message
        negative>{validationError}</Message>}
      {errors === undefined || errors.length === 0 ? '' : <Message
        negative>{errors}</Message>}
      <Table basic={'very'} celled striped>
        <Table.Header>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Created By</Table.HeaderCell>
          {isFamilyAdmin
            ? <Table.HeaderCell>Delete / Add</Table.HeaderCell>
            : ''}
        </Table.Header>
        <Table.Body>
          {body}
        </Table.Body>
      </Table>
    </div>
  )
  }


  export default TransactionPurposeSettings