import React, { useEffect } from 'react'
import { Button, Dimmer, List, Loader, Message, Table } from 'semantic-ui-react'
import { firstLetterToUpper } from '../../helper'

const ScheduledPostingOverview = (props) => {
  const {scheduledPostings, loadScheduledPostings, deleteScheduledPosting} = props

  useEffect( () => { loadScheduledPostings() }, [])

  if(scheduledPostings === undefined || scheduledPostings.payload === undefined || scheduledPostings === {} ){
    return <Dimmer active><Loader content={"Loading"}/></Dimmer>
  }

  const schedulePostingsView = scheduledPostings.payload.map( posting => {
    const isDebit = posting.type === 'debit'
    return (<Table.Row key={posting.id} negative={isDebit}>
      <Table.Cell>{posting.purpose}</Table.Cell>
      <Table.Cell>{posting.amount}</Table.Cell>
      <Table.Cell>{posting.type === 'credit' ? 'Receive' : 'Pay'}</Table.Cell>
      <Table.Cell>{firstLetterToUpper(posting.frequency)}</Table.Cell>
      <Table.Cell>{posting.lastRunDate}</Table.Cell>
      <Table.Cell>{posting.nextRunDate}</Table.Cell>
      <Table.Cell><Button icon={'trash'} onClick={() => deleteScheduledPosting(posting.id)}/></Table.Cell>
    </Table.Row>)
  })

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Purpose</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Pay/Receive</Table.HeaderCell>
          <Table.HeaderCell>Frequency</Table.HeaderCell>
          <Table.HeaderCell>Last Time</Table.HeaderCell>
          <Table.HeaderCell>Next Time</Table.HeaderCell>
          <Table.HeaderCell/>
        </Table.Row>
      </Table.Header>
      {schedulePostingsView}
    </Table>
  )
}

export default ScheduledPostingOverview