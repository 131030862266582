import * as FamilyApi from '../api/family-api'
import * as UserApi from '../api/user-api'

const FAMILY_ADDED = 'family/family-added'
const FAMILY_LOADED = 'family/family-loaded'

export const FAMILY_ADDED_FULFILLED = 'family/family-added_FULFILLED'
export const FAMILY_LOADED_FULFILLED = 'family/family-loaded_FULFILLED'


export function AddFamily(family){
  return {
    type: FAMILY_ADDED,
    payload: FamilyApi.addFamily(family)
  }
}


export function LoadFamily(familyId){
  if(familyId === null || familyId === undefined){
    return {
      type: FAMILY_LOADED_FULFILLED,
      payload: {}
    }
  }
  return {
    type: FAMILY_LOADED,
    payload: FamilyApi.loadFamily(familyId)
  }
}

export function AddUserToFamily(user, avatarData){
  return {
    type: FAMILY_LOADED,
    payload: UserApi.addUser(user, avatarData)
  }
}

export function UpdateUserInFamily(user, avatarData){
  return {
    type: FAMILY_LOADED,
    payload: UserApi.updateUser(user, avatarData)
  }
}

export function DeleteUserFromFamily(id){
  return {
    type: FAMILY_LOADED,
    payload: UserApi.deleteUser(id)
  }
}