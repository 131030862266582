import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserRegistration from '../../components/login/user-registration'

const UserRegistrationContainer = () => {
  const dispatch = useDispatch()
  const registration = useSelector(state => state.registration)
  const referenceData = useSelector(state => state.referenceData)

 return <UserRegistration dispatch={dispatch} registration={registration} referenceData={referenceData} />

}

export default UserRegistrationContainer