import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserSign } from '../../actions/authentication-actions'
import Authentication from '../../components/login/authentication'

function AuthenticationContainer(){
  const dispatch = useDispatch()
  const handleSubmit = (event) => {
    const formData = new FormData(event.target)
    dispatch(UserSign(
      {
        "username": formData.get("username"),
        "password": formData.get("password")
      }
    ))
    event.preventDefault()
  }

  const loggedInUser = useSelector(state => state.user)
  const isAuthenticated = useSelector(state => state.auth)

  return (<Authentication handleSubmit = {handleSubmit} loggedInUser = {loggedInUser} isAuthenticated={isAuthenticated}/>)
}

export default AuthenticationContainer