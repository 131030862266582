import * as AccountApi from '../api/account-api'

// internal events
export const GET_FAMILY_ACCOUNTS = 'acc/get-family-accounts'
export const GET_USER_ACCOUNT = 'acc/get-user-account'

// redux-promise-middleware
export const GET_FAMILY_ACCOUNTS_FULFILLED = 'acc/get-family-accounts_FULFILLED'
export const GET_USER_ACCOUNT_FULFILLED = 'acc/get-user-account_FULFILLED'

export const GET_FAMILY_ACCOUNTS_REJECTED = 'acc/get-family-accounts_REJECTED'
export const GET_USER_ACCOUNT_REJECTED = 'acc/get-user-account_REJECTED'

export const CLEAR_USER_ACCOUNT = 'acc/clear-user-account'

export function GetFamilyAccounts(){
  return {
    type: GET_FAMILY_ACCOUNTS,
    payload: AccountApi.loadFamilyAccounts()
  }
}

export function GetUserAccount(accountId, includePostings , asOf){
  return {
    type: GET_USER_ACCOUNT,
    payload: AccountApi.loadAccount(accountId, includePostings, asOf)
  }
}

export function ClearUserAccount() {
  return {
    type: CLEAR_USER_ACCOUNT,
  }
}


