import { Button, Container, Form, Grid, Input, Message } from 'semantic-ui-react'
import React from 'react'

const CompletePasswordReset = (props) => {
  const { message, handleSubmit } = props

  let messageComp = <div/>

  if(message !== null){
    messageComp = <Message negative style={{textAlign: 'center'}}>{message}</Message>
  }

  return (
    <div>
      {messageComp}
      <Container style={{textAlign:'center'}}>
        Please enter your new password and password confirmation below and submit to complete the process
      </Container>
      <Grid columns={3}>
        <Grid.Column><span/></Grid.Column>
        <Grid.Column>
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <Input name='password' placeholder='Password' type='password'/>
            </Form.Field>
            <Form.Field>
              <Input name='passwordConfirmation' placeholder='Password Confirmation' type='password'/>
            </Form.Field>
            <Button type='submit' fluid color="facebook">Submit</Button>
          </Form>
        </Grid.Column>
        <Grid.Column><span/></Grid.Column>
      </Grid>
    </div>
  )
}

export default CompletePasswordReset