import { BASE_URL } from './common'
import axiosInstance from './base-api'

export async function loadReferenceData(){
  return axiosInstance(BASE_URL + '/reference-data/generic', {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function loadTransactionPurpose(){
  return axiosInstance(BASE_URL + '/reference-data/transaction-purpose', {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function addTransactionPurpose(purpose) {
  let payload = JSON.stringify(purpose)
  return axiosInstance(BASE_URL + '/reference-data/transaction-purpose', {
    method: 'post',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function deleteTransactionPurpose(purpose) {
  let payload = JSON.stringify(purpose)

  return axiosInstance(BASE_URL + '/reference-data/transaction-purpose', {
    method: 'delete',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}

export async function getVersion() {

  return axiosInstance(BASE_URL + '/reference-data/app-version', {
    method: 'get',
    credentials: "include",
    headers: { 'Content-Type': 'application/json' },
    responseType: 'text',
  }).then( res => res.data)
}