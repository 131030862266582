import * as UserApi from '../api/user-api'

// only used internally
const USER_SIGNED_IN = 'auth/user-signed-in'
const CURRENT_USER_LOADED = 'auth/current-user-loaded'
const CHECK_USERNAME_EXISTS = 'auth/check-username'
const CHECK_EMAIL_EXISTS = 'auth/check-email'

// because we use redux-promise-middleware it appends fullfilled or rejected status
export const USER_SIGNED_IN_FULFILLED = 'auth/user-signed-in_FULFILLED'
export const USER_SIGNED_IN_REJECTED = 'auth/user-signed-in_REJECTED'
export const CURRENT_USER_LOADED_FULFILLED = 'auth/current-user-loaded_FULFILLED'
export const CHECK_USERNAME_EXISTS_FULFILLED = 'auth/check-username_FULFILLED'
export const CHECK_USERNAME_EXISTS_REJECTED = 'auth/check-username_REJECTED'
export const CHECK_EMAIL_EXISTS_FULFILLED = 'auth/check-email_FULFILLED'
export const CHECK_EMAIL_EXISTS_REJECTED = 'auth/check-email_REJECTED'
export const REGISTER_USER = 'user/register-user'
export const REGISTER_USER_FULFILLED = 'user/register-user_FULFILLED'

export const USER_AUTHENTICATED_CHECK = 'auth/isAuthenticated'

export const LOGOUT_USER = 'auth/logout-user'

export function UserSign(userDetails){
  return {
    type: USER_SIGNED_IN,
    payload: UserApi.loginUser(userDetails)
  }
}

export function LogoutUser(){
  return {
    type: LOGOUT_USER
  }
}

export function GetCurrentUser(){
  return {
    type: CURRENT_USER_LOADED,
    payload: UserApi.getCurrentUser()
  }
}

export function CheckUsernameExists(username){
  return {
    type:CHECK_USERNAME_EXISTS,
    payload: UserApi.checkIfUsernameExists(username)
  }
}

export function CheckEmailExists(email){
  return {
    type:CHECK_EMAIL_EXISTS,
    payload: UserApi.checkIfEmailExists(email)
  }
}

export function RegisterUser(user){
  return {
    type: REGISTER_USER,
    payload: UserApi.registerUser(user)
  }
}