import { BASE_URL} from './common'
import axiosInstance from './base-api'

export async function loadFamilyAccounts(){
  return axiosInstance(BASE_URL + '/account/family', {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  })
  .then(
    res => { return res.data},
    err => { throw err },
  )
}

export async function loadAccount(accountId, includePostings, asOf){
  let paramList = []
  if ( accountId !== undefined){
    paramList.push("accountId=" + accountId)
  }

  if( includePostings !== undefined) {
    paramList.push("includePostings=" + includePostings)
  }

  if( asOf !== undefined) {
    paramList.push("asOf=" + asOf)
  }

  let url = '/account?' + paramList.join("&")
  console.debug('Calling', BASE_URL + url)
  return axiosInstance(BASE_URL + url, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  })
  .then(
    res => { return res.data },
    err => { throw err },
  )
}