import React, { useEffect, useState } from 'react'
import { Loader } from 'semantic-ui-react'
import SetupFamilyContainer from '../../containers/family/setup-family'
import FamilyDetailsContainer from '../../containers/family/family-details'
const FamilyManagement = ({loadFamily, family, loadingComplete, errors}) => {
  const [startedToLoadFamily, setStartedToLoadFamily] = useState(false)

  useEffect(() => {
    if(!loadingComplete && family === undefined) {
      loadFamily()
      setStartedToLoadFamily(true)
    }
  },[])

  if(!loadingComplete && !loadingComplete){
    return <Loader/>
  }else if (loadingComplete && (family === undefined || family === null)) {
    return <SetupFamilyContainer/>
  }else if (loadingComplete && family !== undefined){
    return <FamilyDetailsContainer family={family} />
  }else {
    console.error('FamilyManagementV2: An error occurred while loading family. We ended up in an unknown state. (loadingComplete: ' + loadingComplete + ', family: ' + family + ')')
  }

  return <div/>
}

export default FamilyManagement