import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PasswordReset from '../../components/login/password-reset'
import { PasswordResetRequest } from '../../model/password-change-request'
import { ResetUserPassword } from '../../actions/user-actions'

const PasswordResetContainer = ()  => {
  const dispatch = useDispatch()
  const passwordReset = useSelector( state => state.settings.passwordReset)

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = new FormData(event.target)
    const request = new PasswordResetRequest(form.get('username'))
    dispatch(ResetUserPassword(request))
  }

  return <PasswordReset handleSubmit={handleSubmit} passwordReset={passwordReset}/>
}

export default PasswordResetContainer