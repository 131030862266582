import {
  ADD_TRANSACTION_PURPOSE_FULFILLED,
  CLEAR_TRANSACTION_PURPOSE,
  DELETE_TRANSACTION_PURPOSE_FULFILLED,
  LOAD_REFERENCE_DATA_FULFILLED,
  LOAD_TRANSACTION_PURPOSE_FULFILLED,
} from '../../actions/reference-data-actions'
import { LOGOUT_USER } from '../../actions/authentication-actions'

const initialState = {
  genders: ['male', 'female'],
  roles: ['Child', 'Parent', 'FamilyAdmin', 'Admin'],
}

export default function refDataReducer (state = initialState, action) {
  switch (action.type) {
    case LOAD_REFERENCE_DATA_FULFILLED:
      return {
        ...state,
        ...action.payload.payload,
        errors: action.payload.errors
      }
    case LOAD_TRANSACTION_PURPOSE_FULFILLED:
      return {
        ...state,
        ...action.payload.payload,
        errors: action.payload.errors
      }
    case ADD_TRANSACTION_PURPOSE_FULFILLED:
      return {
        ...state,
        ...action.payload.payload,
        errors: action.payload.errors
      }
    case DELETE_TRANSACTION_PURPOSE_FULFILLED:
      return {
        ...state,
        ...action.payload.payload,
        errors: action.payload.errors
      }
    case CLEAR_TRANSACTION_PURPOSE: {
      let newState = {...state}
      delete newState['transactionPurpose']
      return newState
    }
    case LOGOUT_USER:
      return initialState
    default:
      return state
  }
}