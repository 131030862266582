import React from 'react'
import { ClearCreatedPostings, CreatePostingSchedule } from '../../actions/posting-actions'
import { useDispatch, useSelector } from 'react-redux'
import AddPostingSchedule
  from '../../components/dashboard/add-posting-schedule'

const AddScheduledPostingContainer = (props) => {
  const dispatch = useDispatch()
  const postingState = useSelector(store => store.posting.created)

  const createPosting = (posting) => {
    dispatch(CreatePostingSchedule(posting))
  }

  const clearPostingCreation = () => { dispatch(ClearCreatedPostings())}

  return <AddPostingSchedule {...props} createScheduledPosting={createPosting}
                             postingState={postingState}
                             clearPostingCreation={clearPostingCreation}
                            />
}

export default AddScheduledPostingContainer