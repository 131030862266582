import React from 'react'
import './footer.css'
import { Icon, Popup, Segment, Table } from 'semantic-ui-react'


const Footer = (props) => {
  const { appVersion } = props
  return (
    <Segment className="segment-bottom" inverted color='black' textAlign='center'>
      <Table inverted color='black' >
          <Table.Body>
          <Table.Row>
            <Table.Cell><span>&copy;</span> 2024 Manage My Pocket Money <span/><Popup trigger={<Icon name={'info circle'}/> } content={"Version: " + appVersion}/></Table.Cell>
            <Table.Cell textAlign='right'>Contact: <a href='mailto:info@managemypocketmoney.com'>info@managemypocketmoney.com</a></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  )

}

export default Footer