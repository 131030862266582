import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import FamilyDetails from '../../components/family/family-details'
import { DeleteUserFromFamily } from '../../actions/family-actions'

const FamilyDetailsContainer = ({family}) => {
  const dispatch = useDispatch()

  // need to show the correct buttons based on user's role in the underlying component.
  // maybe we should put the logic here and pass in the result into the component instead of handling it there.
  const user = useSelector( state => state.user )
  const deleteUser = id => {dispatch(DeleteUserFromFamily(id)) }

  return <FamilyDetails user={user} family={family} deleteUser={deleteUser}/>
}

export default FamilyDetailsContainer