import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AccountDetailsComponent from '../../components/dashboard/account-details-component'
import {
  GetUserAccount,
} from '../../actions/account-actions'
import { LoadPostings } from '../../actions/posting-actions'
import { ClearUserAccount } from '../../actions/account-actions'

const AccountDetailsContainer = (props) => {
  const { account, deletePosting } = props


  const dispatch = useDispatch()
  const defaultProps = { account: undefined, postings: [], range: {}}

  const accountFromState = useSelector(state => state.account.userAccount && state.account.userAccount.payload ? state.account.userAccount.payload : defaultProps).account

  const { range, postings }  = useSelector( state => state.posting.response  )
  const {errors} = useSelector(state => state.account.userAccount ? state.account.userAccount : [])


  // if account was passed in then we are in the parent view, which means we can't just load the users own account information but have to use this
  const isAccountPassedIn = account !== undefined
  const accountToUse = isAccountPassedIn ? account : accountFromState
  const loadUserAccount = (asOf) =>  { dispatch(GetUserAccount(undefined, false, asOf)) }
  const loadGivenAccount = (asOf) =>  { dispatch(GetUserAccount(accountToUse.id, false, asOf)) }

  const loadAccount =  isAccountPassedIn ? loadGivenAccount : loadUserAccount
  const loadPostings = (asOf) => { dispatch(LoadPostings(accountToUse.id, asOf)) }

  const clearUserAccount = () => { dispatch( ClearUserAccount())}
    return (
    <AccountDetailsComponent account={accountFromState} postings={postings} range={range} errors={errors} loadPostings={loadPostings} loadAccount={loadAccount}
                             deletePosting={deletePosting} clearUserAccount={ clearUserAccount }/>
  )
}

export default AccountDetailsContainer