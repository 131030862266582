import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './root-reducer'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

export const store = configureStore({
  reducer: rootReducer,
  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(promise).concat(thunk)
  middleware: [ promise, thunk ]
})
