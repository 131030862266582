import React, { useEffect } from 'react'
import './App.css'
import NavigationContainer from './containers/navigation-container'
import routes from './routes'
import { Container } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { GetCurrentUser} from './actions/authentication-actions'
import {LocalizationProvider} from '@mui/x-date-pickers'
import { AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-gb'
import FooterContainer from './containers/footer'
import './authentication-check'
import { LoadReferenceData } from './actions/reference-data-actions'


function App () {

  const dispatch = useDispatch()
  useEffect( () => {
    dispatch(GetCurrentUser())
    dispatch(LoadReferenceData())
  })

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
      <div>
        <NavigationContainer/>
        <Container className={'content-container'}>{routes}</Container>
        <FooterContainer/>
      </div>
    </LocalizationProvider>
  )
}
export default App
