import React, { useEffect, useState } from 'react'
import { Button, Card, Container, Grid, Header, Icon, Image, Modal, Table } from 'semantic-ui-react'
import './family-details.css'
import ModifyUserContainer from '../../containers/family/modify-user'
import { BASE_URL } from '../../api/common'
import AddUserContainer from '../../containers/family/add-user'
import axiosInstance from '../../api/base-api'

function FamilyDetails (props) {
  const { family, user, deleteUser } = props

  const [addMemberOpen, setAddMemberOpen] = useState(false)
  const [images, setImages] = useState({} )
  const [updateMemberOpen, setUpdateMemberOpen] = useState(Array(family !== undefined && family.members.size).fill(false))

  useEffect(() => {
    const fetchImage = async () => {
      for (const person of family.members) {
        axiosInstance.get(`${BASE_URL}/person/avatar?personId=${person.id}`, {
          responseType: 'blob',
        }).then(response => {
          const blob = new Blob([response.data], { type: 'image/jpeg' })
          const objectUrl = URL.createObjectURL(blob)

          const personId = person.id
          setImages(prevState => {
            return {...prevState, [personId]: objectUrl}
          })
        })
      }
    }

    fetchImage().then(r => console.log(r))
  }, [])

  const isAllowedToChangeFamily = user.roles.includes('FamilyAdmin') ||
    user.roles.includes('Admin')
  // noinspection JSValidateTypes
  return <Grid padded>
      <Grid.Row>
        <Header>Family Overview </Header>
      </Grid.Row>
      <Grid.Row>
        <p>Here you can see and manage your family members. You can add new members, modify existing ones and delete them. </p>
        <p>If you add new children they will automatically receive an account.</p>
      </Grid.Row>
      <Grid.Row>
        <Card.Group>
          {family.members.sort(sortFamilyMembers).map(
            (person, i) => createCard(person, isAllowedToChangeFamily, deleteUser, setUpdateMemberOpen, updateMemberOpen, i))}
          {isAllowedToChangeFamily ? createAddMemberCard(addMemberOpen,
            setAddMemberOpen) : ''}
        </Card.Group>
      </Grid.Row>
    </Grid>
  function createCard (person, isAllowedToChangeFamily, deleteUser, setUpdateMemberOpen, updateMemberOpen, index) {
    const imgSrc = images[person.id]
    const toggleUpdateModal = (indexToUpdate, value) => {
      const newState = [...updateMemberOpen]
      newState[indexToUpdate] = value
      setUpdateMemberOpen(newState)
    }

    const truncatedId = person.id.substring(0, 8) + '...'
    const handleCopyId = () => navigator.clipboard.writeText(person.id)

    const modifyMember = <Card.Content extra>
      <div className="ui two buttons">
        <Modal
          size={'fullscreen'}
          open={updateMemberOpen[index]}
          onOpen={() => toggleUpdateModal(index, true)}
          onClose={() => toggleUpdateModal(index, false)}>
          <Modal.Header>
            <Header block>
              <Header.Content>
                User Details
              </Header.Content>
            </Header>
          </Modal.Header>
          <Modal.Content>
            <ModifyUserContainer toggleModal={(value) => toggleUpdateModal(index, value)} context={'update'} person={person}/>
          </Modal.Content>
        </Modal>
        <Button basic color="blue" onClick={() => toggleUpdateModal(index, true)}>Modify</Button>
        <Button basic color="red" onClick={() => deleteUser(person.id)}>Delete</Button>
      </div>
    </Card.Content>

    return <Card key={person.id}>
      <Card.Content>
        <Image circular floated="right" size="mini" src={imgSrc} className="customImageSize"/>
        <Card.Header>{person.firstName} {person.lastName} </Card.Header>
        <Card.Meta>{person.roles.toString()}</Card.Meta>
        <Card.Description>
          <Table basic="very">
            <Table.Body>
              <Table.Row><Table.Cell>Date of
                Birth</Table.Cell><Table.Cell>{person.dob}</Table.Cell></Table.Row>
              <Table.Row><Table.Cell>Email</Table.Cell><Table.Cell>{person.email}</Table.Cell></Table.Row>
              <Table.Row><Table.Cell>Gender</Table.Cell><Table.Cell>{person.gender}</Table.Cell></Table.Row>
              <Table.Row><Table.Cell>Username</Table.Cell><Table.Cell>{person.username}</Table.Cell></Table.Row>
              <Table.Row><Table.Cell>Id</Table.Cell><Table.Cell>{truncatedId}
                <Icon circular name="copy outline" link
                      onClick={handleCopyId}/></Table.Cell></Table.Row>
            </Table.Body>
          </Table>
        </Card.Description>
        {isAllowedToChangeFamily ? modifyMember : ''}
      </Card.Content>
    </Card>
  }
}

function createAddMemberCard (addMemberOpen, setAddMemberOpen) {
  return (
    <Modal size="fullscreen"
           open={addMemberOpen}
           onClose={() => setAddMemberOpen(false)}
           onOpen={() => setAddMemberOpen(true)}
           trigger={
             <Card key="newMember" className={'ui center aligned'}>
               <Card.Content className="center">
                 <Icon.Group>
                   <Icon size="huge" name="circle outline"/>
                   <Icon size="big" name="plus"/>
                 </Icon.Group>
               </Card.Content>
             </Card>}
    >
      <Modal.Header>Add user to your family</Modal.Header>
      <Modal.Content><AddUserContainer toggleModal={setAddMemberOpen}/></Modal.Content>
    </Modal>
  )
}

const sortFamilyMembers = (a, b) => {
  const rolesA = a.roles.includes('Parent') ? 0 : 1
  const rolesB = b.roles.includes('Parent') ? 0 : 1
  if (rolesA !== rolesB) {
    return rolesA - rolesB
  }
  // Within the Parent and Child groups, sort by first name
  if (a.firstName < b.firstName) {
    return -1
  }
  if (a.firstName > b.firstName) {
    return 1
  }
  return 0
}
export default FamilyDetails