export class Posting {
  constructor (accountId, date, type, amount, purpose, timestamp = null, id=null) {
    this.accountId = accountId
    this.date = date
    this.type = type
    this.amount = amount
    this.purpose = purpose
    this.timestamp = timestamp
    this.id = id
  }}

export class PostingSchedule {
  constructor (accountId, nextRunDate, frequency, type, amount, purpose, timestamp = null, id=null, lastRunDate = null) {
    this.accountId = accountId
    this.nextRunDate = nextRunDate
    this.frequency = frequency
    this.type = type
    this.amount = amount
    this.purpose = purpose
    this.timestamp = timestamp
    this.id = id
    this.lastRunDate = lastRunDate
  }

}