import familyReducer from './reducers/family-reducer'
import refDataReducer from './reducers/ref-data-reducer'
import userReducer from './reducers/user-reducer'
import registrationReducer from './reducers/registration-reducer'
import accountReducer from './reducers/account-reducer'
import postingReducer from './reducers/posting-reducer'
import settingsReducer from './reducers/settings-reducer'
import authReducer from './reducers/auth-reducer'

export default function rootReducer (state = {}, action= {type: 'UNKNOWN'} ) {
  console.debug("type", action.type, 'payload:', action.payload)
  return {
    family: familyReducer(state.family, action),
    referenceData: refDataReducer(state.referenceData, action),
    user: userReducer(state.user, action),
    registration:  registrationReducer(state.registration, action),
    account: accountReducer(state.account, action),
    posting: postingReducer(state.posting, action),
    settings: settingsReducer(state.settings, action),
    auth: authReducer(state.auth, action),
  }
}