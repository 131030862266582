import { BASE_URL } from './common'
import axiosInstance from './base-api'

export async function addFamily (family) {
  let payload = JSON.stringify(family)

  return axiosInstance(BASE_URL + '/family', {
    method: 'post',
    data: payload,
    headers: { 'Content-Type': 'application/json' },
  })
    .then(
      res => { return res.data },
      err => { throw err },
    )
}

export async function loadFamily (familyId) {
  return axiosInstance(BASE_URL + '/family?familyId=' + familyId, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
  })
    .then(
      res => { return res.data },
      err => { throw err },
    )
}