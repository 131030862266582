import React from 'react'
import PasswordSettings from '../../components/settings/password-settings'
import { useDispatch, useSelector } from 'react-redux'
import {ChangeUserPassword} from '../../actions/user-actions'
import { CleanupPasswordChange } from '../../actions/settings-actions'

const PasswordSettingsContainer = (props) => {
  const dispatch = useDispatch()
  const handleSubmit = (passwordChangeRequest) =>  { dispatch(ChangeUserPassword(passwordChangeRequest)) }
  const cleanupPasswordChange = () => dispatch(CleanupPasswordChange())

  const passwordChange = useSelector( state => state.settings.passwordChange)

  return <PasswordSettings handleSubmit={handleSubmit} passwordChange={passwordChange} cleanupPasswordChange={cleanupPasswordChange}/>
}

export default PasswordSettingsContainer